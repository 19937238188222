import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { catchError, filter, lastValueFrom, Observable, of, Subscription, take, throwError, timeout, withLatestFrom } from 'rxjs';
import * as Ably from 'ably';
import { Quality, QualitySelectorComponent } from '../ui-kit/ui-quality-selector/quality-selector.component';
import { MatDialog } from '@angular/material/dialog';
import { WebrtcService } from '../../development/webrtc.service';
import { AblyTopics, AblyTopicsStr } from '@models/ably.model';
import { PlaybackError, PlaybackEvent, PlaybackWsAction, PlaybackWsEvent, WbRTCActiveSession, WebRTCPeerControlTypes, WebRTCPeerInterface, WebRTCPeerInterfaceControl, WebRTCPeerInterfacePlaybackSeek, WebRTCPeerInterfacePlaybackSpeed, WebRTCPeerInterfacePlaybackStart, WebRtcPeerType, WebRTCPlaybackError, WebRTCPlaybackInfo, WebRTCPlaybackInfoRequest } from '@models/webrtc.model';
import { UiAreaSelection } from '../ui-kit/ui-area-selector/ui-area-selector.component';
import { Point } from '@angular/cdk/drag-drop';
import { WebrtcDebugDialogComponent, WebrtcDebugDialogData } from '../../framework/webrtc-player/webrtc-debug-dialog/webrtc-debug-dialog.component';
import * as uuid from 'uuid';
import { UtilsV2Service } from '../../services/utils-v2.service';
import { CamerasService } from '../../cameras/cameras.service';
import { MediasoupService, MediasoupSession } from 'src/app/framework/mediasoup/mediasoup.service';
import { select, Store } from '@ngrx/store';
import { StorageActions } from '@states/storage/storage.action-types';
import { PreloaderColor } from '@enums/shared.enum';
import { WebRTCActiveSessionSelectors } from '@states/webrtc-active-sessions/webrtc-active-sessions.selector-types';
import { WebRtcActiveSessionActions } from '@states/webrtc-active-sessions/webrtc-active-sessions.action-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { FadeOutAnimation } from '../../framework/animations';
import { ActiveOrganization, LiveViewType } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import { VisibilityChanged } from '../directives/visibility-change.directive';
import { LockService } from 'src/app/development/lock.service';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { SocketEvents } from '../../socket/socket.model';
import { SocketMainService } from 'src/app/socket/socket-main.service';
import { SocketEdgePlaybackService } from 'src/app/socket/socket-edge-playback.service';
import { LivekitService } from '../../development/livekit.service';
import { LiveKitModels } from '@models/livekit.model';
import Hls, { ErrorData, Events, HlsListeners, ManifestParsedData } from 'hls.js';
import { AuthenticationService } from '../../authentication/authentication.service';
import { QualitySelectorV2Component } from '../ui-kit/ui-quality-selector-v2/quality-selector-v2.component';
import { WallSelectors } from '@states/wall/wall.selector-types';
import { UiParticipantsDialogComponent, UiParticipantsDialogData } from '../ui-kit/ui-participants-dialog/ui-participants-dialog.component';
import { LiveStreamModels } from '@models/live-stream.model';
import { MediaCacheService } from '../media-cache/media-cache.service';
import { MultiPlaybackMove } from '@models/multi-playback.model';
import { MultiPlaybackSelectors } from '@states/multi-playback/multi-playback.selector-types';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import StreamResolution = LiveStreamModels.StreamResolution;
import { HttpService } from '../../core/http.service';
import { EdgeActions } from '@states/edge/edge.action-types';
import { StatsService } from '../../development/stats.service';

const DEFAULT_EXTEND_TIME = 120000;
const HEALTHCHECK_INTERVAL_TIME = 10000;
const MAX_RECOVERIES = 6;

const hlsConfig = {
  enableWorker: true,
  lowLatencyMode: true,
  liveSyncDurationCount: 1,
  liveMaxLatencyDurationCount: 2,
  initialLiveManifestSize: 1,
  backBufferLength: 30,
  highBufferWatchdogPeriod: 1,
  liveDurationInfinity: true,

};

export interface LocalPlayerState {
  sessionId?: string;
  ws_protocol: string;
  ws_hostname: string;
  ws_port: number;

  queue: any;
  startTimeSet: boolean;
  playbackOptionSequence: boolean;
  sourceBuffer: SourceBuffer;
  streamingStarted: boolean;
  videoStarted: boolean;
  webSocket;
  enableVerboseMsgLog: boolean;
  mimeCodec: string;
  ms?: MediaSource;
  lastBound?: number;
  errorCounter?: number;
  resolution?: LiveStreamModels.StreamResolution;
}

//todo move to models.
export interface WebrtcPlayerState {
  stream?: MediaStream;
  configuration?: RTCConfiguration;
  hq: boolean;
  pc?: any;
  relay?: boolean;
  sessionSubscription?: Subscription;
  incomingIce?: Partial<RTCIceCandidate>[];
  receivedOffer?: boolean;
  sessionId?: string;
}

interface WebrtcStats {
  output: string;
  bitrate: number;
  prevTimestamp: number;
  prevBytesReceived: number;
}

interface ZoomState {
  scale: number;
  initX: number;
  initY: number;
  moveX: number;
  moveY: number;
  boundryX: number;
  boundryY: number;
  dragging: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-webrtc-v2',
  templateUrl: './webrtc-v2.component.html',
  styleUrls: ['./webrtc-v2.component.scss'],
  animations: [FadeOutAnimation],
})
export class WebrtcV2Component implements OnInit, OnDestroy {

  public LiveViewType = LiveViewType;
  public selectIsLocal$: Observable<boolean>;
  public selectLocalUrl$: Observable<string>;
  public selectEdgeSwVersion$: Observable<string>;
  public selectEdgeLastMp4Ts$: Observable<number>;
  public selectHasSubstream$: Observable<boolean>;

  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('player', { static: true }) playerObj: ElementRef;
  @ViewChild('preview') previewWrapper: ElementRef;
  @ViewChild('previewCanvas') previewCanvas: ElementRef;
  @ViewChild('placeholder') placeholder: ElementRef;
  @ViewChild('placeholderCanvas') placeholderCanvas: ElementRef;
  @ViewChild('qualitySelector') qualitySelector: QualitySelectorComponent;
  @ViewChild('resolutionSelector') resolutionSelector: QualitySelectorV2Component;
  @ViewChild('videoWrapper') videoWrapper: ElementRef;

  @Output() onStreamError = new EventEmitter(null);
  @Output() resetError = new EventEmitter(null);
  @Output() playing: EventEmitter<string> = new EventEmitter<string>();
  @Output() isRelay: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() pause: EventEmitter<void> = new EventEmitter<void>();
  @Output() _forceMp4: EventEmitter<void> = new EventEmitter<void>();

  @Input() public isRespectRatio: boolean = true;
  @Input() public cameraId;
  @Input() public edgeId;
  @Input() public locationId;
  @Input() public showZoomButtons = true;
  @Input() public allowZoom = true;
  @Input() public autostart = false;
  @Input() public forceRelay = false;
  @Input() public enableQualitySelection = true;
  @Input() public accessToken: string;
  @Input() public offline: boolean = false;
  @Input() public cameraName: string;
  /**
   * Show/hide made responsive some elements on tile to make better UX in small tile
   */
  @Input() public isSmallTile: boolean = false;

  @Input() public playback = false;
  @Input() public liveView = false;
  @Input() public playbackTS: number;
  @Input() public playbackSeek = false;
  @Input() public duration: number;
  @Input() public disableExtend = false;
  @Input() public enableHealthCheck = true;
  @Input() public isExternallyManaged = false;
  @Input() public cameraView = false;
  @Input() public zoomPreview = true;
  @Input() public hideQuality = false;

  public pauseTs: number;

  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  public isDeveloper$ = this.store$.pipe(select(OrganizationSelectors.isDeveloper));
  public isTileSelected$ = this.store$.pipe(select(WallSelectors.selectedIsTileSelected));
  public wallCamerasNum$: Observable<number> = this.store$.select(WallSelectors.selectCamerasNum);
  public selectMove$: Observable<MultiPlaybackMove> = this.store$.select(MultiPlaybackSelectors.selectMove)
    .pipe(untilDestroyed(this));

  public selectDragging$: Observable<boolean> = this.store$.select(MultiPlaybackSelectors.selectDragging)
    .pipe(untilDestroyed(this));


  private eventListeners: { [key: string]: EventListenerOrEventListenerObject } = {};

  public preloaderColor = PreloaderColor;
  public inactive = false;
  private extendInterval;
  // public ably: AblyState = {};
  // private ablyOptions: Ably.Types.ClientOptions;
  private ablyClient: Ably.Realtime;
  private ablyChannel: Ably.RealtimeChannel;
  public sessionId: string;

  @Input() mediasoup = false;
  public mediasoupSession: MediasoupSession = {};

  public wsSeeking = false;
  public wsShouldReset = false;

  rtc_configuration: RTCConfiguration = {
    iceServers: [{ urls: 'stun:stun.l.google.com:19302' },
    ],
  };

  public zoomState: ZoomState = {
    scale: 1,
    initX: 0,
    initY: 0,
    moveX: 0,
    moveY: 0,
    boundryX: 0,
    boundryY: 0,
    dragging: false,
  };

  state: WebrtcPlayerState = {
    hq: true,
    pc: null,
    relay: false,
    incomingIce: [],
    receivedOffer: false,
    configuration: this.rtc_configuration,
  };

  stats: WebrtcStats = {
    output: '',
    bitrate: 0,
    prevTimestamp: 0,
    prevBytesReceived: 0,
  };

  // Additional Flags and declarations
  origHeight = 0;
  origWidth = 0;
  ratio = 0;

  ctx: CanvasRenderingContext2D;
  placeholderCtx;

  public loader = false;
  statsInterval;

  recover = true;
  qualityChange = false;

  public disableInactivity = true;
  public video;
  @Input() public developer = false;
  private _isRelay = false;

  public isPlaceholder = false;
  public started = false;

  placeholderInterval;

  public counters = {
    start: 0,
    stop: 0,
    sessionGenerate: 0,
  };

  public health = {
    prevTime: 0,
    interval: null,
  };

  public playbackErrors = {
    internal: false,
    maxSessions: false,
    error: null,
    statusCode: null,
  };

  @Output() noMoreFiles: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.origHeight = this.playerObj.nativeElement.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
    if (this.zoomState.scale > 1 && !!this.previewCanvas) {
      this.previewCanvas.nativeElement.width = this.origWidth * 0.25;
      this.previewCanvas.nativeElement.height = this.origHeight * 0.25;
    }
    this.setPlaceholder(true);
    this.cd.detectChanges();
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event) {
    if (this.sessionId || !!this.liveKitSession) {
      this.stop();
    }
  }

  @HostListener('document:fullscreenchange', []) fullscreenChanged(ev) {
    this.cd.detectChanges();
    const width = this.placeholderCanvas.nativeElement.width;
    this.placeholderCanvas.nativeElement.height = 100;

  }

  private isViewInitCompleted = false;
  private pendingChanges: SimpleChanges[] = [];

  private baseUrl;
  public snapshotFallback = '';

  private localStateInitialValue: LocalPlayerState = {

    ws_protocol: 'wss',
    ws_hostname: '192.168.100.116',
    ws_port: null,

    queue: [],
    startTimeSet: false,
    playbackOptionSequence: false,
    sourceBuffer: null,
    streamingStarted: false,
    videoStarted: false,
    webSocket: null,
    enableVerboseMsgLog: false,
    mimeCodec: '',
    ms: null,
    lastBound: null,
    errorCounter: 0,
    resolution: LiveStreamModels.StreamResolution.HQ,
  };

  public localState: LocalPlayerState = _.cloneDeep(this.localStateInitialValue);

  public isLocal = false;
  public localUrl = '';
  private liveStreamControlSubscription: Subscription;
  private wsSessionsTokens: Set<string> = new Set();

  private windowHidden = false;
  private hideTs: number;
  private showTs: number;

  public localLiveStreamEnabled = false;

  public recoverCount = 0;
  public lastRecovery: number;
  public errorMsg: string;

  private stopTimeout;

  public wsPlayback = false;
  public wsPlaybackCommandSubscription: Subscription;
  public wsPlaybackVideoSubscription: Subscription;

  public first = true;
  public socketEdgePlaybackConnected$: Observable<boolean> = this.socketEdgePlaybackService.connectionSubject.asObservable()
    .pipe(filter(connected => !!connected), take(1));

  public liveKitSession: LiveKitModels.LiveKitSession;

  public liveViewType: LiveViewType = LiveViewType.Webrtc;

  public hls: Hls | null = null;
  private hlsErrorCounter = 0;
  private wallCameraNum = 0;
  private hlsDebug = false;
  private liveStreamDebug = true;

  private userPause = false;
  private paused = false;

  private forceWebrtcRelay = false;
  private hlsForceSQ = false;

  private hlsPlaybackTs: number;
  public hlsPlaybackDuration: number;
  public hlsPlaybackSessionId: string;
  private moveTs: number;
  private dragging = false;

  private lastMp4Ts: number;
  private forceMp4 = false;

  private videoEventListeners: { [key: string]: EventListenerOrEventListenerObject } = {};
  private hlsEventListeners: Partial<HlsListeners> = {};

  canPlayTimeout: any;

  maskTimeUpdate: boolean = false;
  prevResolution: LiveStreamModels.StreamResolution;

  constructor(
    private ngZone: NgZone,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private webrtcService: WebrtcService,
    private utilsV2Service: UtilsV2Service,
    private camerasService: CamerasService,
    private mediasoupService: MediasoupService,
    private store$: Store,
    private elementRef: ElementRef,
    private lockService: LockService,
    private socketMainService: SocketMainService,
    private socketEdgePlaybackService: SocketEdgePlaybackService,
    private livekitService: LivekitService,
    private authenticationService: AuthenticationService,
    private mediaCacheService: MediaCacheService,
    private httpService: HttpService,
    private statsService: StatsService,
  ) {
  }

  public get currentTime(): number {
    return this.playback && !!this.hls ? 0 : this.video?.currentTime;
  }

  public get showRetry() {
    return this.recoverCount === MAX_RECOVERIES && !this.isPlaying;
  }

  public getPreviewCanvas() {
    return this.previewCanvas;
  }

  public get isLiveKit() {
    return this.liveViewType === LiveViewType.Livekit;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isViewInitCompleted) {
      this.executeChanges(changes);
    } else {
      this.pendingChanges.push(changes);
    }
  }

  sleep(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  public setExistingSession(session: WbRTCActiveSession) {
    this.state = this.webrtcService.getActiveSession(this.edgeId, this.cameraId);
    this.rtc_configuration = this.state.configuration;
    // if (this.forceWebrtcRelay) {
    //   this.rtc_configuration.iceTransportPolicy = 'relay';
    // }
    this.sessionId = session.id;
  }

  public async executeChanges(changes: SimpleChanges) {
    // if (changes['liveView']) {
    //   console.log('liveView changed', this.liveView);
    //   this.playback = !this.liveView;
    // }
    if (this.isExternallyManaged && this.isLiveKit) {
      this.isExternallyManaged = false;
    }
    if (changes['cameraId'] && this.autostart) {
      if (this.isLocal && this.localLiveStreamEnabled) {
        this.localState.webSocket?.close();
        this.playerObj?.nativeElement.pause();
        this.play();
        return;
      }
      if (!this.isExternallyManaged) {
        let randomNumber = Math.random() * 20;
        let roundedNumber = Math.round(randomNumber) * 50;
        await this.sleep(roundedNumber);
      }
      if (this.ablyClient) {
        this.destroyAbly();
      }
      this.initAbly();
      this.resetState();
      this.resetError.emit();
      if (this.isExternallyManaged) {
        this.store$.select(WebRTCActiveSessionSelectors.selectActiveSessionByEdgeIdCameraId({
            edgeId: this.edgeId,
            cameraId: this.cameraId,
          }))
          .pipe(untilDestroyed(this), take(1))
          .subscribe(
            (session) => {
              if (!!session) {
                if ((new Date().getTime() - session.timestamp > DEFAULT_EXTEND_TIME)) {
                  this.store$.dispatch(WebRtcActiveSessionActions.deleteActiveSession({
                    edgeId: this.edgeId,
                    cameraId: this.cameraId,
                  }));
                } else {
                  this.setExistingSession(session);
                  if (this.autostart) {
                    this.play();
                  }
                }
              } else {
                if (this.lockService.isLocked(`webrtc-${this.cameraId}`)) {
                  this.store$.select(WebRTCActiveSessionSelectors.selectActiveSessionByEdgeIdCameraId({
                      edgeId: this.edgeId,
                      cameraId: this.cameraId,
                    }))
                    .pipe(untilDestroyed(this), filter(session => !!session), take(1))
                    .subscribe(
                      (session) => {
                        this.setExistingSession(session);
                        if (this.autostart) {
                          this.play();
                        }
                      });
                } else {
                  this.lockService.acquireLock(`webrtc-${this.cameraId}`);
                  if (this.autostart) {
                    this.play();
                  }
                }
              }

            },
          );
      } else if (this.autostart) {
        this.play();
      }
    }
  }

  async initAbly() {
    const ablyOptions = {
      key: 'E1YILg.eTBPUQ:m4VkVmbMO6ftJBdS4GLcayKXXn8bp8cXj3B-jBkjIDA',
      echoMessages: false,
    };
    this.ablyClient = new Ably.Realtime(ablyOptions); /* inferred type Ably.Realtime */
    this.ablyChannel = this.ablyClient.channels.get(this.edgeId); /* inferred type Ably.Types.RealtimeChannel */

    this.ablyChannel.attach();
    // Getting presence on a channel
    this.ablyChannel.presence.get();

    this.ablyChannel
      .subscribe(AblyTopics[this.playback ? AblyTopics.Playback : AblyTopics.WebRTCPeer], async (message) => {

        const peerData: WebRTCPeerInterface = message.data;
        if (peerData.sessionId !== this.sessionId) {
          return;
        }
        let timestamp = 0;
        if (peerData?.timestamp !== timestamp && peerData?.type !== undefined) {
          switch (peerData.type) {
            case WebRtcPeerType.ICE:
              if (this.state.receivedOffer) {
                await this.onIncomingICE(peerData.ice);
              } else {
                this.state.incomingIce.push(peerData.ice);
              }
              break;
            case WebRtcPeerType.SDP:

              await this.onIncomingSDP(peerData.sdp);
              break;
            default:
              switch (peerData.control) {
                case WebRTCPeerControlTypes.Close:
                  this.recover = false;
                  this.stop();
                  break;
                case WebRTCPeerControlTypes.PlaybackInfo:
                  const data: WebRTCPlaybackInfo = peerData as WebRTCPlaybackInfo;
                  this.store$.dispatch(StorageActions.setOfflineStorageStats({ offlineStorage: data.noStorage }));
                  this.store$.dispatch(StorageActions.setSmartStorageStats({ smartStorage: data.smartStorage }));
                  break;
                case WebRTCPeerControlTypes.PlaybackError:
                  const err: WebRTCPlaybackError = peerData as WebRTCPlaybackError;
                  switch (err.error) {
                    case PlaybackError.InternalError:
                      this.playbackErrors.internal = true;
                      break;
                    case PlaybackError.SessionLimitReached:
                      this.playbackErrors.maxSessions = true;
                      break;
                    case PlaybackError.NoMoreFiles:
                      this.noMoreFiles.emit();
                      break;
                  }
                  this.stop(false, true, false);
                  break;
                default:
                  break;
              }
              break;
          }
        }
      });
    await this.ablyClient.connection.once('connected');
  }

  async publishMsg(msg: any) {
    if (!this.ablyChannel || this.ablyClient.connection.state !== 'connected') {
      return;
    }
    return this.ablyChannel?.publish(this.playback ? AblyTopicsStr[AblyTopics.Playback] : AblyTopicsStr[AblyTopics.WebRTCPeer], JSON.stringify({ data: msg, time: new Date().getTime() }));
  }

  setupHls(connectionData: LiveStreamModels.ConnectionData): void {
    const { resolution, url } = connectionData;
    this.localState.resolution = resolution;
    this.qualityChange = false;
    if (this.hlsDebug) {
      console.log(`[HLS] starting hls stream at resolution:`, resolution, 'url:', url);
    }
    try {
      // Create a new video element
      this.clearVideo();
      // this.video = this.createVideoElement();

      const video = this.video;
      const hlsUrl = this.playback ? url : `https://${this.localState.ws_hostname}/${url}`;
      if (this.hlsDebug) {
        console.log(`[HLS] hlsUrl:`, hlsUrl);
      }

      // Cleanup existing HLS instance
      if (this.hls) {
        Object.keys(this.hlsEventListeners)
          .forEach((eventKey) => {
            const event = eventKey as keyof HlsListeners;
            const listener = this.hlsEventListeners[event];
            if (listener) {
              this.hls.off(event, listener);
            }
          });
        this.hlsEventListeners = {};

        this.hls.destroy();
        this.hls = null;
      }

      const lastFiveMinutes = Date.now() - 5 * 60 * 1000;
      if (this.hlsPlaybackTs > lastFiveMinutes) {
        hlsConfig['startPosition'] = 0;
        delete hlsConfig['liveMaxLatencyDurationCount'];
      } else {
        hlsConfig['liveMaxLatencyDurationCount'] = 2;
        delete hlsConfig['startPosition'];
      }

      if (Hls.isSupported()) {
        this.ngZone.runOutsideAngular(() => {
          this.hls = new Hls(hlsConfig);
          this.hls.loadSource(hlsUrl);
          this.hls.attachMedia(video);

          // Store event handlers with correct types
          this.hlsEventListeners[Events.MANIFEST_PARSED] = (event: Events.MANIFEST_PARSED, data: ManifestParsedData) => {
            this.hlsPlaybackDuration = this.hls.levels[0].details.totalduration;
            console.log('[HLS] Manifest parsed, duration:', this.hlsPlaybackDuration);

            this.ngZone.run(() => {
              this.playVideo();
            });
            if (!this.disableExtend) {
              if (this.extendInterval) {
                clearInterval(this.extendInterval);
              }
              this.extendInterval = setInterval(() => {
                this.extend();
              }, DEFAULT_EXTEND_TIME);
            }
          };
          this.hls.on(Events.MANIFEST_PARSED, this.hlsEventListeners[Events.MANIFEST_PARSED]);

          this.hlsEventListeners[Events.ERROR] = (event: Events.ERROR, data: ErrorData) => {
            if (data.fatal) {
              console.error('[HLS] Fatal HLS.js error:', event, data);

              this.hlsErrorCounter++;
              console.log('[HLS] Error counter:', this.hlsErrorCounter);

              this.recoverHls();
            }
          };
          this.hls.on(Events.ERROR, this.hlsEventListeners[Events.ERROR]);
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = hlsUrl;

        // Store event handlers to remove them later
        this.videoEventListeners['loadedmetadata'] = () => {
          this.playVideo();
          if (!this.disableExtend) {
            if (this.extendInterval) {
              clearInterval(this.extendInterval);
            }
            this.extendInterval = setInterval(() => {
              this.extend();
            }, DEFAULT_EXTEND_TIME);
          }
        };
        video.addEventListener('loadedmetadata', this.videoEventListeners['loadedmetadata']);

        this.videoEventListeners['error'] = (event) => {
          const error = video.error;
          console.error('[HLS] Video element error:', error);
          this.hlsErrorCounter++;
          this.recoverHls();
        };
        video.addEventListener('error', this.videoEventListeners['error'], { once: true });
      }
    } catch (error) {
      console.error('[HLS] Error setting up HLS:', error);
      this.hlsErrorCounter++;
      console.log('[HLS] Fatal error counter:', this.hlsErrorCounter);
      this.recoverHls();
    }
  }

  private createVideoElement(): HTMLVideoElement {
    const video = document.createElement('video');
    video.controls = true;
    video.muted = true;
    video.autoplay = false;
    video.playsInline = true;
    this.playerObj.nativeElement.parentNode.replaceChild(video, this.playerObj.nativeElement);
    this.playerObj.nativeElement = video;
    return video;
  }

  async ngOnInit(): Promise<void> {

    if (!this.autostart) {
      this.initAbly();
    }
    if (!!this.liveView) {
      this.playback = !this.liveView;
    }

    this.selectMove$.subscribe(move => {
      this.moveTs = move.timestamp;
    });

    this.selectDragging$.subscribe(dragging => {
      this.dragging = dragging;
    });
  }

  resetErrors() {
    this.playbackErrors = {
      internal: false,
      maxSessions: false,
      error: null,
      statusCode: null,
    };
  }

  resetState(resetErrors = true) {
    this.state = {
      hq: this.state.hq,
      pc: null,
      incomingIce: [],
      receivedOffer: false,
      configuration: this.rtc_configuration,
    };
    if (this.video) {
      this.video.srcObject = null;
    }
    if (resetErrors) {
      this.resetErrors();
    }
  }

  async ngAfterViewInit(): Promise<void> {
    const video = this.playerObj.nativeElement;
    this.placeholderCtx = this.placeholderCanvas.nativeElement.getContext('2d');

    if (!this.cameraView) {
      this.wallCamerasNum$.pipe(untilDestroyed(this), take(1))
        .subscribe((num) => {
          this.wallCameraNum = num;
          if (this.wallCameraNum) {
            const res = this.selectHlsResolution();
            this.localState.resolution = res;
            switch (this.localState.resolution) {
              case LiveStreamModels.StreamResolution.SQ:
                this.state.hq = false;
                // console.log(`Resolution set to SQ`);
                break;
              case LiveStreamModels.StreamResolution.MQ:
                this.state.hq = false;
                // console.log(`Resolution set to MQ`);
                break;
              case LiveStreamModels.StreamResolution.HQ:
                this.state.hq = true;
                // console.log(`Resolution set to HQ`);
                break;
            }
          }
        });
    }
    this.selectIsLocal$ = this.store$.pipe(select(EdgeSelectors.selectLocalById(this.edgeId)));
    this.selectLocalUrl$ = this.store$.pipe(select(EdgeSelectors.selectLocalBaseUrlById(this.edgeId)));
    this.selectEdgeSwVersion$ = this.store$.pipe(select(EdgeSelectors.selectEdgeSwVersionById(this.edgeId)));
    this.selectEdgeLastMp4Ts$ = this.store$.pipe(select(EdgeSelectors.selectEdgeLastMp4Ts(this.edgeId)));
    this.selectHasSubstream$ = this.store$.pipe(select(CameraSelectors.selectCameraHasSubstreamsById(this.cameraId)));

    this.selectEdgeLastMp4Ts$.pipe(untilDestroyed(this))
      .subscribe(ts => {
        if (!!ts) {
          this.lastMp4Ts = ts;
        } else {
          this.statsService.getEdgeLastMp4(this.edgeId)
            .subscribe(lastMp4Ts => {
              this.lastMp4Ts = lastMp4Ts;
            });
        }
      });

    this.selectActiveOrganization$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(async org => {
        // if (org?.videoQuality) {
        //   this.state.hq = org.videoQuality === Quality.HQ;
        // }
        if (!!org?.localLiveStream) {
          this.localLiveStreamEnabled = true;
        }

        if (this.localLiveStreamEnabled) {
          this.selectIsLocal$.pipe(untilDestroyed(this), withLatestFrom(this.selectLocalUrl$))
            .subscribe(([res, localUrl]) => {
              if (this.isLocal === res) {
                return;
              }
              if (this.playback) {
                return;
              }
              const sessionId = this.sessionId;
              const localSessionId = this.localState?.sessionId;
              this.stop();
              this.isLocal = res;
              const playing = true; //!!sessionId || !!localSessionId || !!this.liveKitSession;
              this.localUrl = localUrl;

              if (this.isLocal && !this.liveStreamControlSubscription) {
                this.liveStreamControlSubscription = this.socketMainService.consume<void>(SocketEvents.liveStreamNotification)
                  .pipe(untilDestroyed(this))
                  .subscribe((res) => {
                    if (this.liveStreamDebug) {
                      console.log('camera live stream response:', res);
                    }
                    const token = res['sessionToken'];
                    if (!this.wsSessionsTokens.has(token)) {
                      return;
                    }
                    const port = res['connectionData'][this.cameraId]?.port;
                    if (port) {
                      this.localState.ws_port = port;
                      this.openWSConnection(this.localState.ws_protocol, this.localState.ws_hostname, this.localState.ws_port);
                    } else {
                      const connectionData: LiveStreamModels.ConnectionData = res['connectionData'][this.cameraId];
                      const status = connectionData.cameraStatus;
                      if (!connectionData.errorMsg && status) {
                        if (!this.hls) {
                          this.setupHls(connectionData);
                        }
                      } else {
                        console.log('[HLS] Camera status is not true');
                        if (connectionData.errorMsg) {
                          console.log(`[HLS] ${connectionData.errorMsg}`);
                        }
                        if (connectionData.errorCode === LiveStreamModels.LiveViewErrorCode.Unsupported) {
                          this.recoverHls();
                        } else {
                          this.recoverHls();
                        }
                      }
                    }
                    this.wsSessionsTokens.delete(token);
                  });
              } else {
                if (this.liveStreamControlSubscription) {
                  this.liveStreamControlSubscription.unsubscribe();
                  delete this.liveStreamControlSubscription;
                }
              }
              if (playing) {
                this.stop();
                setTimeout(() => {
                  this.play();
                }, 1000);
              }
            });
        }

        if (!!org?.webSocketPlayback) {
          this.wsPlayback = true;
        }

        if (!!org?.forceWebrtcRelay) {
          this.forceWebrtcRelay = true;
        }


        if (!!org?.liveViewType) {
          this.liveViewType = org.liveViewType;
          if (this.isLiveKit) {
            this.selectHasSubstream$.pipe(take(1))
              .subscribe(hasSubstream => {
                if (!hasSubstream) {
                  this.localState.resolution = StreamResolution.HQ;
                } else {
                  this.localState.resolution = this.wallCameraNum > 1 ? LiveStreamModels.StreamResolution.MQ : StreamResolution.HQ;
                }
              });
          }
        }

      });


    this.initPlayer();

    if (this.allowZoom) {
      if (this.zoomPreview) {
        this.ctx = this.previewCanvas?.nativeElement?.getContext('2d');
        video.addEventListener('play', () => {
          this.timerCallback();
        });
      }
    }
    this.onResize();
    this.isViewInitCompleted = true;

    // Execute any accumulated changes
    for(const changes of this.pendingChanges) {
      this.executeChanges(changes);
    }
    // Clear the accumulated changes after processing
    this.pendingChanges = [];
  }

  initPlayer() {
    this.video = this.playerObj.nativeElement as HTMLVideoElement;
    this.video.muted = true;
    this.video.controls = false;
  }

  public get peerDataBase() {
    return {
      locationId: this.locationId,
      edgeId: this.edgeId,
      cameraId: this.cameraId,
      timestamp: new Date().getTime(),
      sessionId: this.sessionId,
      cloud: true,
    };
  }

  public stopHlsPlayback() {
    const request: LiveStreamModels.StopHlsPlaybackRequest = {
      cameraId: this.cameraId,
      sessionId: this.hlsPlaybackSessionId,
    };
    this.camerasService.stopHlsPlayback(request)
      .subscribe();
  }

  public async start(ts?: number) {
    // console.log('start() not hidden');
    // console.log(`[WEBRTC-START] cameraId: ${this.cameraId}, sessionId: ${this.sessionId}`);
    if (this.playback) {
      if (!ts) {
        console.log('Received null ts');
        return;
      }
      return this.playbackStart(ts);
    }
    this.counters.start++;
    const rtcConfiguration = this.state.configuration;
    // console.log(rtcConfiguration);
    const peerData = <WebRTCPeerInterfaceControl>{
      ...this.peerDataBase,
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.Start,
      rtcConfiguration,
      hq: this.state.hq,
    };
    await this.publishMsg(peerData);
    if (!this.playback) {
      this.camerasService.startLiveView(this.edgeId, this.locationId, this.cameraId, false, this.state.hq)
        .subscribe();
    }
  }

  public async playbackStart(ts: number) {
    if (!ts) {
      console.log('Received null ts 897');
      return;
    }
    this.counters.start++;
    // console.log(`[WEBRTC-START] cameraId: ${this.cameraId}, sessionId: ${this.sessionId}`);
    const isHlsPlayback = this.isHlsPlayback(ts);
    if (isHlsPlayback) {
      if (this.sessionId) {
        await this.stopWebrtcSession(false, true);
      }
      const now = Date.now();
      const lastFiveMinutes = now - 5 * 60 * 1000;

      const end = Math.min(now - 2000, ts + 30 * 60 * 1000);

      ts = Math.floor(ts);
      const request: LiveStreamModels.StartHlsPlaybackRequest = {
        cameraId: this.cameraId,
        edgeId: this.edgeId,
        locationId: this.locationId,
        smartStorage: false,
        start: now - ts <= 4000 ? 0 : ts,
        end: ts > lastFiveMinutes ? 0 : end,
      };

      if (this.hlsPlaybackSessionId) {
        request.sessionId = this.hlsPlaybackSessionId;
      }

      this.loader = true;
      this.camerasService.startHlsPlayback(request)
        .pipe(catchError((err) => {
          console.log(err);
          const errObj = err.error.error;
          if (errObj.statusCode === LiveStreamModels.HlsPlaybackErrors.NO_HLS) {
            this.forceMp4 = true;
            this._forceMp4.emit();
            this.stop();
            if (request.start >= Date.now() - 60 * 1000) {
              this.play();
            }
            return of(err);
          }
          this.playbackErrors.error = errObj.error;
          this.playbackErrors.statusCode = errObj.statusCode;
          this.clearHealthCheck();
          this.loader = false;
          return throwError(err);
        }))
        .subscribe((res) => {
          const connectionData: LiveStreamModels.ConnectionData = {
            cameraStatus: true,
            url: res.url,
            resolution: StreamResolution.AUTO,
          };
          this.hlsPlaybackTs = ts;
          this.setupHls(connectionData);
          this.hlsPlaybackSessionId = res.sessionId;
          console.log('start playback response', res);
        });
      return;
    }
    if (this.hlsPlaybackSessionId) {
      delete this.hlsPlaybackSessionId;
      this.stop();
    }

    const rtcConfiguration = this.state.configuration;
    const peerData = <WebRTCPeerInterfacePlaybackStart>{
      ...this.peerDataBase,
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.PlaybackStart,
      rtcConfiguration,
      hq: this.state.hq,
      playbackTS: ts,
      duration: this.duration,
    };
    await this.publishMsg(peerData);
    this.setMove(0.5, ts);

    this.camerasService.startWebrtcPlayback(this.edgeId, this.locationId, this.cameraId, ts, this.sessionId)
      .subscribe();
  }

  async checkHiddenDocument(visibilityChanged: VisibilityChanged) {
    this.windowHidden = visibilityChanged.hidden;
    let hideShowDelta = 0;
    if (this.windowHidden) {
      this.hideTs = Date.now();
    } else {
      this.showTs = Date.now();
      hideShowDelta = this.showTs - this.hideTs;
    }
    const sessionId = this.sessionId;
    const localSessionId = this.localState?.sessionId;
    const playing = !!sessionId || !!localSessionId;
    if (!visibilityChanged.hidden) {
      // Tab is visible change
      if (!this.isLocal && !this.sessionId && !this.hlsPlaybackSessionId && !this.liveKitSession?.clientToken) {
        console.log('extend called without session id');
        await this.play();
        return;
      } else {
        // console.log(this.sessionId);
        if (!this.isLocal) {
          if (this.sessionId) {
            await this.extend();
            this.healthCheck();
            setTimeout(() => {
              this.healthCheck();
            }, 5000);
          }
        } else {
          // Deprecated
          /**
           if (!this.playback && this.localLiveStreamEnabled && playing) {
           if (!this.localState?.ms) {
           this.play();
           } else {
           clearTimeout(this.stopTimeout);
           }
           }
           **/
          // For local HLS
          if (!this.playback && this.localLiveStreamEnabled && this.started && hideShowDelta < DEFAULT_EXTEND_TIME) {
            await this.extend();
          } else {
            this.play();
          }
        }
      }
    } else {
      // Tab is hidden change
      if (this.isLocal && this.localState?.sessionId) {
        this.stopTimeout = setTimeout(() => {
          const sessionId = this.localState?.sessionId;
          this.stop();
          this.localState.sessionId = sessionId;
        }, 3000);
      }
    }
  }

  public selectHlsResolution() {
    if (this.cameraView) {
      return LiveStreamModels.StreamResolution.HQ;
    }
    const elem = this.elementRef.nativeElement;
    const height = elem.clientHeight;

    if (height < 380) {
      return LiveStreamModels.StreamResolution.SQ;
    } else if (height < 800) {
      return this.wallCameraNum <= 16 ? LiveStreamModels.StreamResolution.MQ : LiveStreamModels.StreamResolution.SQ;
    } else {
      if (this.wallCameraNum <= 4) {
        return LiveStreamModels.StreamResolution.HQ;
      } else if (this.wallCameraNum <= 16) {
        return LiveStreamModels.StreamResolution.MQ;
      } else {
        return LiveStreamModels.StreamResolution.SQ;
      }
    }
  }

  public async extend() {
    if (document.hidden) {
      return;
    }
    if (this.isLocal && !this.playback) {
      console.log('HLS Extend');
      // (await this.camerasService.startHLSStreamOld(this.edgeId, [this.cameraId], this.localState.resolution, this.elementRef.nativeElement.clientWidth, this.elementRef.nativeElement.clientHeight, this.hlsErrorCounter))
      //   .subscribe();
      const request: LiveStreamModels.StartHLSLocalStreamRequest = {
        numberOfCameras: this.wallCameraNum,
        pixelDensity: window.devicePixelRatio,
        edgeId: this.edgeId,
        locationId: this.locationId,
        cameraId: this.cameraId,
        width: this.elementRef.nativeElement.clientWidth,
        height: this.elementRef.nativeElement.clientHeight,
      };

      if (this.localState.resolution !== StreamResolution.AUTO) {
        request.resolution = this.localState.resolution;
      } else {
        if (this.hlsForceSQ) {
          request.resolution = LiveStreamModels.StreamResolution.SQ;
        }
      }

      this.camerasService.startLocalHls(request)
        .pipe(untilDestroyed(this),
          catchError((err) => {
            this.fallBackToWebrtc();
            return of(err);
          }))
        .subscribe(_ => {
          this.playVideo();
        });
      return;
    }
    if (!this.hls) {
      const peerData = <WebRTCPeerInterfaceControl>{
        ...this.peerDataBase,
        type: WebRtcPeerType.CONTROL,
        control: this.playback ? WebRTCPeerControlTypes.PlaybackExtend : WebRTCPeerControlTypes.Extend,
      };
      await this.publishMsg(peerData);
    }
  }

  public async seek(ts: number) {
    console.log('[SEEK]');
    const isHlsPlayback = this.isHlsPlayback(ts);
    if (isHlsPlayback) {
      this.maskTimeUpdate = true;
      this.loader = true;
      clearTimeout(this.canPlayTimeout);
      if (this.sessionId) {
        delete this.sessionId;
      }
      if (!!this.hlsPlaybackSessionId) {
        this.video.pause();
        const lastFiveMinutes = Date.now() - 5 * 60 * 1000;
        if (ts < lastFiveMinutes && ts > this.hlsPlaybackTs && ts < this.hlsPlaybackTs + this.hlsPlaybackDuration * 1000) {
          console.log('Seeking to ', new Date(ts));
          const video = this.playerObj.nativeElement;
          const seekTime = (ts - this.hlsPlaybackTs) / 1000; // Convert milliseconds to seconds
          video.currentTime = seekTime; // Use the correct property
          this.health.prevTime = 0;
          this.playVideo();
        } else {
          await this.stop();
          if (!ts) {
            console.log('Received null ts 1090');
            return;
          }
          this.playbackStart(ts);
        }
      } else {
        if (!ts) {
          console.log('Received null ts 1096');
          return;
        }
        this.playbackStart(ts);
      }
    } else {
      this.httpService.cancelPendingRequests();
      if (this.hlsPlaybackSessionId) {
        delete this.hlsPlaybackSessionId;
      }
      const peerData = <WebRTCPeerInterfacePlaybackSeek>{
        ...this.peerDataBase,
        type: WebRtcPeerType.CONTROL,
        control: WebRTCPeerControlTypes.PlaybackSeek,
        playbackTS: ts,
      };
      await this.publishMsg(peerData);
      this.video.pause();
      this.video.currentTime = 0;
      setTimeout(() => {
        this.video.play();
      }, 500);
    }
  }

  public async speed(speed: number) {
    const peerData = <WebRTCPeerInterfacePlaybackSpeed>{
      ...this.peerDataBase,
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.PlaybackSpeed,
      playbackSpeed: speed,
    };
    await this.publishMsg(peerData);
  }

  public async publishStop() {
    if (this.sessionId) {
      const peerData = <WebRTCPeerInterfaceControl>{
        ...this.peerDataBase,
        type: WebRtcPeerType.CONTROL,
        control: this.playback ? WebRTCPeerControlTypes.PlaybackStop : WebRTCPeerControlTypes.Stop,
        hq: this.state.hq,
        errorMsg: this.errorMsg,
      };

      this.sessionId = null;

      await this.publishMsg(peerData);
    }
  }

  public async stopWebrtcSession(inactive?: boolean, resetErrors = true) {
    if (this.state.sessionSubscription) {
      this.state.sessionSubscription.unsubscribe();
    }
    if (this.statsInterval) {
      clearInterval(this.statsInterval);
    }

    this.state?.pc?.close();
    this.resetState(resetErrors);
    if (inactive) {
      this.inactive = true;
    }

    await this.publishStop();

    if (this.isExternallyManaged && this.lockService.isLocked(`webrtc-${this.cameraId}`)) {
      this.lockService.releaseLock(`webrtc-${this.cameraId}`);
    }
  }

  public async stop(inactive?: boolean, placeholder = true, resetErrors = true) {
    console.log('[STOP]');
    if (placeholder) {
      this.setPlaceholder();
    }

    if (this.isLiveKit) {
      await this.liveKitDisconnect();
    }

    // Clear video element
    this.clearVideo();

    // Clear health check if enabled
    if (this.enableHealthCheck) {
      this.clearHealthCheck();
    }
    // Clear extend interval if enabled
    if (this.extendInterval) {
      clearInterval(this.extendInterval);
    }

    this.loader = false;
    this.counters.stop++;


    if (!this.playback) {
      /** Stop live view **/
      /* If it is a local live stream - stop local live stream */
      if (this.localLiveStreamEnabled && this.isLocal) {
        if (this.hls) {
          Object.keys(this.hlsEventListeners)
            .forEach((eventKey) => {
              const event = eventKey as keyof HlsListeners;
              const listener = this.hlsEventListeners[event];
              if (listener) {
                this.hls.off(event, listener);
              }
            });
          this.hlsEventListeners = {};

          this.hls.destroy();
          this.hls = null;
        }
        if (this.localState.sessionId) {
          this.localState.webSocket?.close();
          this.clearMediaSource();
          this.localState = _.cloneDeep(this.localStateInitialValue);
        }
        return;
      }
      /* Non local */
      switch (this.liveViewType) {
        case LiveViewType.Livekit:
          if (!this.liveKitSession) {
            return;
          }
          const stopLiveKitRequest: LiveKitModels.StopLiveKitRequest = {
            edgeId: this.edgeId,
            cameraId: this.cameraId,
            resolution: this.prevResolution ?? this.localState.resolution,
          };
          delete this.liveKitSession;
          this.livekitService.stop(stopLiveKitRequest)
            .subscribe(() => {
              console.log('[LiveKit] Sent livekit stop');

            });
          return;
        case LiveViewType.Webrtc:
          this.stopWebrtcSession(inactive, resetErrors);
      }
      this.camerasService.stopLiveView(this.edgeId, this.locationId, this.cameraId)
        .subscribe();
    } else {
      /** Stop playback **/
      if (this.wsPlayback) {
        this.leaveRoom();

      } else {
        if (this.hls) {
          delete this.hlsPlaybackTs;
          delete this.hlsPlaybackDuration;
          Object.keys(this.hlsEventListeners)
            .forEach((eventKey) => {
              const event = eventKey as keyof HlsListeners;
              const listener = this.hlsEventListeners[event];
              if (listener) {
                this.hls.off(event, listener);
              }
            });
          this.hlsEventListeners = {};

          this.hls.destroy();
          this.hls = null;

        } else {
          this.stopWebrtcSession(inactive, resetErrors);
        }
      }
    }

    // console.log(`==> [WEBRTC-STOP] cameraId: ${this.cameraId}, sessionId: ${this.sessionId}`);

    // MEDIASOUP - deprecated
    // if (!this.playback && this.mediasoup) {
    //   this.mediasoupService.cleanup(this.mediasoupSession);
    // }
  }

  setPlaceholder(resize = false) {
    const ratio = this.video?.videoWidth / this.video?.videoHeight;
    const placeholderElem = this.placeholder?.nativeElement;

    if (!placeholderElem) {
      return;
    }

    placeholderElem.style.height = `100%`;
    const width = this.isRespectRatio ? placeholderElem.clientHeight * ratio + 'px' : `100%`;
    placeholderElem.style.width = width;

    this.placeholderCanvas.nativeElement.width = placeholderElem.clientWidth;
    this.placeholderCanvas.nativeElement.height = placeholderElem.clientHeight;
    if (!resize) {
      this.placeholderCtx.drawImage(this.playerObj.nativeElement, 0, 0, placeholderElem.clientWidth, placeholderElem.clientHeight);
    }
    this.isPlaceholder = true;
  }


  async changeResolution(resolution: LiveStreamModels.StreamResolution) {
    console.log('changeResolution', resolution);
    const isAuto = this.localState.resolution === LiveStreamModels.StreamResolution.AUTO || resolution === LiveStreamModels.StreamResolution.AUTO;

    if (!this.isLocal && this.isLiveKit && !isAuto) {
      const origRes = this.localState.resolution;
      this.prevResolution = origRes;
      this.localState.resolution = resolution;
      if (origRes === LiveStreamModels.StreamResolution.HQ || this.localState.resolution === LiveStreamModels.StreamResolution.HQ) {
        if (origRes !== resolution) {
          await this.stop();
          this.play();
        }
      } else {
        this.livekitService.changeQuality(this.liveKitSession.room, resolution);
      }
      return;
    }
    this.qualityChange = true;
    this.localState.resolution = resolution;

    this.loader = true;
    await this.stop();
    this.play();
  }

  changeQuality(resolution: LiveStreamModels.StreamResolution) {
    const quality = resolution === LiveStreamModels.StreamResolution.HQ ? Quality.HQ : Quality.SQ;
    this.qualityChange = true;
    this.loader = true;
    // set placeholder size to video size
    this.setPlaceholder();
    this.resetZoom();
    this.stop();
    if (quality === Quality.SQ) {
      this.state.hq = false;
    } else {
      this.state.hq = true;
    }
    if (!this.playback && this.mediasoup) {
      this.mediasoupService.cleanup(this.mediasoupSession);
    }
    this.play();
  }

  public async playExisting() {
    this.loader = true;
    this.inactive = false;
    if (!this.disableExtend) {
      if (this.extendInterval) {
        clearInterval(this.extendInterval);
      }
      this.extendInterval = setInterval(async () => {
        await this.extend();
      }, DEFAULT_EXTEND_TIME);
      await this.extend();
    }
    this.video.srcObject = this.state.stream;
    this.video.load();
    this.video.removeEventListener('loadeddata', this.onVideoLoad);
    this.video.addEventListener('loadeddata', this.onVideoLoad);
    setTimeout(() => {
      this.playVideo();
    }, 3000);
    if (this.enableHealthCheck) {
      this.startHealthCheck();
    }
    this.initStats();
    this.loader = false;
    this.started = true;
  }

  public async recoverHls() {
    // this.hlsForceSQ = true;
    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }
    this.stop();
    if (!!this.hlsPlaybackTs) {
      this.play({ ts: this.moveTs });
    } else {
      this.play();
    }
  }

  public async fallBackToWebrtc(changeType = false) {
    if (this.liveViewType !== LiveViewType.Webrtc && changeType) {
      await this.liveViewTypeChange(LiveViewType.Webrtc);
    }
    this.loader = false;
    this.isLocal = false;
    delete this.sessionId;
    if (this.liveStreamControlSubscription) {
      this.liveStreamControlSubscription.unsubscribe();
      delete this.liveStreamControlSubscription;
    }
    this.localState.webSocket?.close();
    this.clearMediaSource();
    const resolution = this.localState.resolution;
    this.localState = _.cloneDeep(this.localStateInitialValue);
    this.localState.resolution = resolution;
    this.resetState();
    if (!this.ablyClient) {
      this.initAbly();
    }
    this.play();
  }

  public async sendStartLocalStream(hls = true) {
    this.loader = true;
    if (hls && this.hls) {
      this.hls.destroy();
      this.hls = null;
    }

    const request: LiveStreamModels.StartHLSLocalStreamRequest = {
      numberOfCameras: this.wallCameraNum,
      pixelDensity: window.devicePixelRatio,
      edgeId: this.edgeId,
      locationId: this.locationId,
      cameraId: this.cameraId,
      width: this.elementRef.nativeElement.clientWidth,
      height: this.elementRef.nativeElement.clientHeight,
    };

    if (this.qualityChange || this.localState.resolution !== StreamResolution.AUTO) {
      request.resolution = this.localState.resolution;
    } else {
      if (this.hlsForceSQ) {
        request.resolution = LiveStreamModels.StreamResolution.SQ;
      }
    }

    console.log('[HLS] start hls request', request);

    this.camerasService.startLocalHls(request)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        console.log('local hls start response', res);
        const connectionData: LiveStreamModels.ConnectionData = res['connectionData'][this.cameraId];
        const status = connectionData.cameraStatus;
        if (!connectionData.errorMsg && status) {
          if (!this.hls) {
            this.setupHls(connectionData);
          }
        } else {
          console.log('[HLS] Camera status is not true');
          if (connectionData.errorMsg) {
            console.log(`[HLS] ${connectionData.errorMsg}`);
          }
          if (connectionData.errorCode === LiveStreamModels.LiveViewErrorCode.Unsupported) {
            this.fallBackToWebrtc();
          } else {
            this.recoverHls();
          }
        }
      });
    // const op = hls
    //   ? this.camerasService.startHLSStream(request)
    //   : this.camerasService.startLocalCamerasStream(this.edgeId, [this.cameraId]);
    // op.pipe(catchError(err => {
    //     console.log('[HLS] start hls request failed');
    //     return [];
    //   }))
    //   .subscribe((res) => {
    //     this.wsSessionsTokens.add(res?.token?.session);
    //   });
  }

  public startLocalStream() {
    this.localState.ws_hostname = this.localUrl; //`${this.edgeId}.lumixai-eth0.com`;
    if (!this.localState.ws_hostname) {
      this.localState.ws_hostname = this.localUrl; //`${this.edgeId}.lumixai.com`;
      this.sendStartLocalStream();
    } else {
      this.sendStartLocalStream();
    }
  }

  public leaveRoom() {
    this.socketEdgePlaybackService.emit(SocketEvents.playbackWebEvent, {
      event: PlaybackWsEvent.WebLeaveRoom,
      sessionId: this.sessionId,
    });
    if (this.wsPlaybackVideoSubscription) {
      this.wsPlaybackCommandSubscription.unsubscribe();
    }
    if (this.wsPlaybackCommandSubscription) {
      this.wsPlaybackCommandSubscription.unsubscribe();
    }
    this.socketEdgePlaybackService.disconnect();
    this.clearMediaSource();
  }

  public async emitPlaybackControlEvent(event: PlaybackWsEvent, action: PlaybackWsAction, ts: number) {
    this.socketEdgePlaybackService.emit(SocketEvents.playbackWebEvent, {
      event,
      action,
      sessionId: this.sessionId,
      ts,
    });
  }

  public isPlaybackWsValid(): Observable<boolean> {
    return of()
      .pipe(
        filter(() => this.socketEdgePlaybackService.isValid()),
        take(1));
  }

  clearBufferAndSeekToStart() {
    const sourceBuffer = this.localState.sourceBuffer;
    if (sourceBuffer && sourceBuffer.buffered.length > 0) {
      const start = sourceBuffer.buffered.start(0);
      const end = sourceBuffer.buffered.end(sourceBuffer.buffered.length - 1);
      this.localState.queue = [];

      if (sourceBuffer.updating) {
        sourceBuffer.addEventListener('updateend', () => {
          sourceBuffer.remove(start, end);
        }, { once: true });
      } else {
        // Clear the buffer
        sourceBuffer.remove(start, end);
      }

    }
  }

  public playLiveKit() {
    const authProviderId = this.authenticationService.getAuthProviderIdFromLocalStorage();
    if (!this.liveKitSession) {
      this.liveKitSession = {
        roomName: `${this.edgeId}-${this.cameraId}`,
      };
      const getLiveKitTokenRequest: LiveKitModels.CreateLiveKitTokenRequest = {
        participantName: `${authProviderId}-${uuid.v4()}`,
        edgeId: this.edgeId,
        cameraId: this.cameraId,
        resolution: this.localState?.resolution,

      };
      const getLiveKitServerTokenRequest: LiveKitModels.CreateLiveKitTokenRequest = {
        participantName: uuid.v4(),
        edgeId: this.edgeId,
        cameraId: this.cameraId,
        canSubscribe: false,
      };
      this.livekitService.getToken(getLiveKitTokenRequest)
        .pipe(
          timeout(5000),
          catchError(err => {
            console.log(err);
            // this.fallBackToWebrtc();
            return [];
          }),
        )
        .subscribe((clientTokenRes) => {
          const clientToken = clientTokenRes?.token;

          this.liveKitSession = {
            clientToken,
            roomName: `${this.edgeId}-${this.cameraId}`,
            room: null,
          };
          console.log(clientToken);
          this.loader = true;
          if (this.enableHealthCheck) {
            this.startHealthCheck();
          }
          this.livekitService.connect(clientToken, this.playerObj.nativeElement, this.localState?.resolution === LiveStreamModels.StreamResolution.AUTO, this.localState?.resolution)
            .then(({ room, play$ }) => {
              if (!this.liveKitSession) {
                return;
              }
              this.liveKitSession.room = room;
              console.log('[LiveKit] Connected to LiveKit');
              play$.pipe(take(1))
                .subscribe(() => {
                  console.log('webrtc given attached livekit stream');
                  this.playVideo();
                });

            })
            .catch(error => {
              console.error('[LiveKit] Failed to connect to LiveKit', error);
            });
        });
      return;
    }
  }

  public async startWsPlayback(data?: { ts?: number, start?: number, end?: number }) {
    this.localLiveStreamEnabled = true;
    this.isLocal = true;
    if (this.sessionId) {
      console.log('[WS PLAYBACK SEEK]');
      const video = this.playerObj.nativeElement;
      video.pause();
      this.wsSeeking = true;
      this.wsShouldReset = true;
      this.clearBufferAndSeekToStart();
      await this.emitPlaybackControlEvent(PlaybackWsEvent.Control, PlaybackWsAction.Seek, data.ts);
      return;
    }
    console.log('[WS PLAYBACK] Connecting...');
    this.sessionId = uuid.v4();
    this.socketEdgePlaybackService.connect();
    await lastValueFrom(this.socketEdgePlaybackConnected$);
    console.log('[WS PLAYBACK] Connected');
    this.socketEdgePlaybackService.emit(SocketEvents.playbackWebJoin, {
      edgeId: this.edgeId,
      cameraId: this.cameraId,
      sessionId: this.sessionId,
      timestamp: data?.ts,
    });

    this.wsPlaybackCommandSubscription = this.socketEdgePlaybackService.consume(`playback:web:command:${this.sessionId}`)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        console.log(`[playback:web:command]: `, res);
        const playbackEvent: PlaybackEvent = res as unknown as PlaybackEvent;
        switch (playbackEvent.event) {
          case PlaybackWsEvent.CodecInfo:
            if (!this.localState?.ms) {
              const mimeCodec: string = playbackEvent.payload;
              this.setMimeCodec(mimeCodec);
              console.log('Got MIME codec ' + this.localState?.mimeCodec);
            }
            break;
          case PlaybackWsEvent.Control:
            const action: PlaybackWsAction = playbackEvent.action;
            switch (action) {
              case PlaybackWsAction.Seek:
                this.wsSeeking = false;
                this.isPlaceholder = false;
            }
            break;
        }
      });

    this.wsPlaybackVideoSubscription = this.socketEdgePlaybackService.consume(`playback:web:video-stream:${this.sessionId}`)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (this.wsSeeking) {
          return;
        }
        const arrayBuffer = <ArrayBuffer>res['data'];
        const blob = new Blob([new Uint8Array(arrayBuffer)]);
        this.onWebsocketMessage(blob);
      });

    return;
  }

  public async startWebrtcSession(data?: { ts?: number, start?: number, end?: number }) {
    if (this.sessionId) {
      this.setPlaceholder();
      this.stop();
    }
    this.loader = true;
    this.inactive = false;
    if (!this.disableExtend) {
      if (this.extendInterval) {
        clearInterval(this.extendInterval);
      }
      this.extendInterval = setInterval(() => {
        this.extend();
      }, DEFAULT_EXTEND_TIME);
    }
    // Call get credentials
    if (!this.rtc_configuration['password']) {
      try {
        const res = await lastValueFrom(this.webrtcService.getCredentials(this.accessToken));
        this.state.configuration = res;
        // if (this.forceWebrtcRelay) {
        //   this.state.configuration.iceTransportPolicy = 'relay';
        // }
        this.rtc_configuration = this.state.configuration;
      } catch (e) {
        this.state.configuration = this.rtc_configuration;
      }
    }
    this.sessionId = uuid.v4();
    this.state.sessionId = this.sessionId;
    this.counters.sessionGenerate++;

    this.start(data?.ts);
    if (this.enableHealthCheck && !this.health?.interval) {
      this.startHealthCheck();
    }
    await this.createCall();
  }

  public isHlsPlayback(ts?: number) {

    return ts > this.lastMp4Ts && !this.forceMp4;
  }

  public async play(data?: { ts?: number, start?: number, end?: number }) {
    if (document.hidden) {
      return;
    }

    console.log(`[PLAY]`, data);
    // Reset errors
    this.recover = true;
    delete this.errorMsg;
    this.resetErrors();
    if (data?.ts) {
      this.playback = true;
    }

    if (!this.playback) {
      /** Live view **/
      /* If local live stream is enabled and this is a local camera */
      if (this.localLiveStreamEnabled && this.isLocal) {
        if (!this.locationId) {
          return;
        }
        this.startLocalStream();
        return;
      }

      switch (this.liveViewType) {
        case LiveViewType.Livekit:
          return this.playLiveKit();
        case LiveViewType.Webrtc:
          /* If this webrtc session is externally managed and already exist - play it */
          if (this.isExternallyManaged && this.state?.sessionId) {
            await this.playExisting();
            return;
          }
          /* Otherwise - create a new session */
          await this.startWebrtcSession(data);
          break;
      }
    } else {
      /** Playback **/
      /* If Websocket playback is enabled */
      if (this.wsPlayback) {
        return this.startWsPlayback(data);
      }
      /* Otherwise - WebRTC / HLS playback */

      // Reset user pause
      this.userPause = false;
      this.paused = false;

      const isHlsPlayback = this.isHlsPlayback(data?.ts);
      if (isHlsPlayback) {
        if (!data?.ts) {
          console.log('Received null ts 1707');
          return;
        }
        if (this.playbackSeek && this.hlsPlaybackSessionId && !this.paused && !this.liveView) {
          await this.seek(data?.ts);
          return;
        }

        return this.playbackStart(data?.ts);
      } else {
        // If playback is already playing - this is a seek event
        if (this.playbackSeek && this.sessionId && !this.paused && !this.liveView) {
          await this.seek(data?.ts);
          return;
        }
        // Playback is not already playing - create a new webrtc session
        await this.startWebrtcSession(data);
      }

    }

    // Mediasoup - Deprecated
    // if (!this.playback && this.mediasoup) {
    //   // this.start();
    //   this.initPlayer();
    //   this.mediasoupSession = await this.mediasoupService.connect(`${this.edgeId}-${this.cameraId}-${this.state.hq ? 'hq' : 'sq'}`, this.video);
    //   this.mediasoupSession.playingSubscription = this.mediasoupSession.playingSubject.subscribe(playing => {
    //     if (playing) {
    //       this.playing.emit(null);
    //       this.started = true;
    //       this.loader = false;
    //       this.isPlaceholder = false;
    //       this.qualityChange = false;
    //       if (!!this.qualitySelector) {
    //         this.qualitySelector.loading = false;
    //       }
    //       if (this.enableHealthCheck) {
    //         this.startHealthCheck();
    //       }
    //     }
    //   });
    //   this.mediasoupService.start(this.edgeId, this.cameraId, this.state.hq)
    //     .subscribe(async _ => {
    //     });
    //
    //   return;
    // }

  }

  public zoomIn() {
    this.zoom({ deltaY: -1 });
    if (this.zoomPreview) {
      this.computeFrame();
    }
  }

  public async getStorage(start: number, end: number) {
    const peerData = <WebRTCPlaybackInfoRequest>{
      ...this.peerDataBase,
      type: WebRtcPeerType.CONTROL,
      control: WebRTCPeerControlTypes.PlaybackInfoRequest,
      startTS: start,
      endTS: end,
    };
    // console.log(`[ABLY PLAYBACK INFO REQUEST] ${peerData.startTS}[${new Date(peerData.startTS)}] - ${peerData.endTS}${new Date(peerData.endTS)}`);
    await this.publishMsg(peerData);
  }

  public zoomOut() {
    this.zoom({ deltaY: 1 });
  }

  public zoomArea(area: UiAreaSelection) {
    if (!area?.start?.x || !area?.start?.y || !area?.end?.x || !area?.end?.y) {
      return;
    }
    this.initZoom();
    const wrapper = this.wrapper.nativeElement;
    const topLeft: Point = {
      x: Math.min(area.start.x, area.end.x),
      y: Math.min(area.start.y, area.end.y),
    };
    const width = Math.abs(area.start.x - area.end.x);
    const height = this.ratio * width;
    if (width <= 0 || height <= 0) {
      return;
    }
    this.zoomState.scale = wrapper.clientWidth / width;
    const elem = this.playerObj.nativeElement;

    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale})`);
    if (this.zoomState.scale !== 1) {
      this.calcBoundaries();
    }
    this.zoomState.moveX = this.zoomState.boundryX - topLeft.x;
    this.zoomState.moveY = this.zoomState.boundryY - topLeft.y;

    this.zoom(undefined, true);
  }

  calcBoundaries() {
    const elem = this.playerObj.nativeElement;
    const height = this.origHeight * this.zoomState.scale;
    const width = this.origWidth * this.zoomState.scale;
    this.zoomState.boundryX = width >= elem.clientWidth ? (width - elem.clientWidth) / 2 / this.zoomState.scale : 0;
    this.zoomState.boundryY = (height - elem.clientHeight) / 2 / this.zoomState.scale;
  }

  public initZoom() {
    const elem = this.playerObj.nativeElement;
    this.ratio = elem.videoWidth / elem.videoHeight;
    this.origHeight = elem.clientHeight;
    this.origWidth = this.origHeight * this.ratio;
  }

  public zoom(event, renderOnly = false) {
    if (event?.type === 'mousewheel') {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!this.allowZoom) {
      return;
    }
    if (!this.ratio) {
      this.initZoom();
    }
    const elem = this.playerObj.nativeElement;
    if (!renderOnly) {
      if (event.deltaY < 0) {
        if (this.zoomState.scale < 5) {
          this.zoomState.scale += 0.2;
        } else {
          this.zoomState.scale = 5;
        }
        this.onResize();
        this.cd.detectChanges();
      } else {
        if (this.zoomState.scale >= 1.2) {
          this.zoomState.scale -= 0.2;
        } else {
          this.zoomState.scale = 1;
        }
      }
    }
    if (this.zoomState.scale === 1) {
      this.zoomState.moveX = 0;
      this.zoomState.moveY = 0;
      this.zoomState.boundryX = 0;
      this.zoomState.boundryY = 0;
    }

    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale}) translate(${this.zoomState.moveX}px, ${this.zoomState.moveY}px)`);
    if (this.zoomState.scale !== 1) {
      this.calcBoundaries();
    }
    const moveXDir = Math.sign(this.zoomState.moveX);
    const moveYDir = Math.sign(this.zoomState.moveY);
    this.zoomState.moveX = Math.abs(this.zoomState.moveX) > this.zoomState.boundryX ? this.zoomState.boundryX * moveXDir : this.zoomState.moveX;
    this.zoomState.moveY = Math.abs(this.zoomState.moveY) > this.zoomState.boundryY ? this.zoomState.boundryY * moveYDir : this.zoomState.moveY;
    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale}) translate(${this.zoomState.moveX}px, ${this.zoomState.moveY}px)`);
  }

  dragStart(event: MouseEvent) {
    this.zoomState.dragging = true;
    this.zoomState.initX = event.clientX - this.zoomState.moveX;
    this.zoomState.initY = event.clientY - this.zoomState.moveY;
  }

  drag(event: MouseEvent) {
    if (this.zoomState.scale === 1 || !this.zoomState.dragging || (event.movementX === 0 && event.movementY === 0)) {
      return;
    }
    const elem = this.playerObj.nativeElement;
    const rect = elem.getClientRects('2d')[0];

    const ratio = elem.videoWidth / elem.videoHeight;
    this.zoomState.moveX += Math.abs(this.zoomState.moveX + event.movementX) >= this.zoomState.boundryX ? 0 : event.movementX;
    this.zoomState.moveY += Math.abs(this.zoomState.moveY + event.movementY) >= this.zoomState.boundryY ? 0 : event.movementY;
    this.renderer.setStyle(elem, 'transform', `scale(${this.zoomState.scale}) translate(${this.zoomState.moveX}px, ${this.zoomState.moveY}px)`);
  }

  public maximize() {
    if (!!this.video.requestFullscreen) {
      this.video.requestFullscreen();
    } else if (!!this.video.mozRequestFullScreen) {
      this.video.mozRequestFullScreen();
    } else if (!!this.video.webkitRequestFullscreen) {
      this.video.webkitRequestFullscreen();
    }
    const isPlaceholder = this.isPlaceholder;
    this.setPlaceholder(true);
    if (!isPlaceholder) {
      this.isPlaceholder = false;
    }

  }

  timerCallback() {
    if (this.playerObj.nativeElement.paused || this.playerObj.nativeElement.ended) {
      return;
    }
    this.computeFrame();
    setTimeout(() => {
      this.timerCallback();
    }, 1000);
  }

  computeFrame() {
    this.ctx.drawImage(this.playerObj.nativeElement, 0, 0, this.previewCanvas?.nativeElement.width, this.previewCanvas?.nativeElement.height);
  }

  public inZoom() {
    return this.zoomState.scale > 1;
  }

  calculateBitrate(timestamp: number, bytesReceived: number) {

    if (!!this.stats.prevTimestamp && !!this.stats.prevBytesReceived) {
      const bitrate = 8 * (bytesReceived - this.stats.prevBytesReceived) / (timestamp - this.stats.prevTimestamp);
      this.stats.bitrate = Math.max(0, bitrate); // ((bytesReceived - this.prevBytesReceived) / 128) / ((timestamp - this.prevTimestamp) / 1000);
    }

    this.stats.prevTimestamp = timestamp;
    this.stats.prevBytesReceived = bytesReceived;
  }

  public resetZoom() {
    this.zoomState.scale = 1;
    this.zoom(undefined, true);
  }

  initStats() {
    if (this.statsInterval) {
      clearInterval(this.statsInterval);
    }
    this.statsInterval = setInterval(() => {
      this.state?.pc?.getStats(null)
        .then((stats) => {
          this.stats.output = '';

          if (this.state.stream) {
            this.stats.output += `<h2>Stream settings</h2>\n`;
            if (this.state.stream.getVideoTracks()[0]) {
              const settings = this.state.stream.getVideoTracks()[0]?.getSettings();
              if (settings) {
                for(let [key, value] of Object.entries(settings)) {
                  this.stats.output += `${key}: ${value}<br>\n`;
                }
              }
            }
          }
          let activeCandidatePair;
          stats.forEach(report => {
            if (report.type === 'transport') {
              activeCandidatePair = stats.get(report.selectedCandidatePairId);
            }
          });

          // Firefox workaround
          if (!activeCandidatePair) {
            stats.forEach(report => {
              if (report.type === 'candidate-pair' && report.selected) {
                activeCandidatePair = report;
              }
            });
          }
          if (!!activeCandidatePair) {
            const localCandidate = stats.get(activeCandidatePair.localCandidateId);
            if (localCandidate.candidateType === 'relay') {
              // Stream is not local - enable inactivity feature
              this.disableInactivity = false;
              if (this._isRelay !== true) {
                this._isRelay = true;
                if (this.isExternallyManaged) {
                  this.store$.dispatch(WebRtcActiveSessionActions.updateSessionLocal({
                    edgeId: this.edgeId, cameraId: this.cameraId, isLocal: false,
                  }));
                } else {
                  this.store$.dispatch(WebRtcActiveSessionActions.setActiveSession({
                    edgeId: this.edgeId,
                    cameraId: this.cameraId,
                    sessionState: this.state,
                    isLocal: false,
                    unmanaged: true,
                  }));
                }
                this.isRelay.emit(true);
              }
            } else {
              // Stream is local - disable inactivity feature
              this.disableInactivity = true;
              if (this._isRelay !== false) {
                this._isRelay = false;
                if (this.isExternallyManaged) {
                  this.store$.dispatch(WebRtcActiveSessionActions.updateSessionLocal({
                    edgeId: this.edgeId, cameraId: this.cameraId, isLocal: true,
                  }));
                } else {
                  this.store$.dispatch(WebRtcActiveSessionActions.setActiveSession({
                    edgeId: this.edgeId,
                    cameraId: this.cameraId,
                    sessionState: this.state,
                    isLocal: true,
                    unmanaged: true,
                  }));
                }
                this.isRelay.emit(false);
              }
            }
          }

          stats.forEach((report) => {

            if (report.type === 'inbound-rtp' && report.kind === 'video') {
              this.calculateBitrate(report.timestamp, report.bytesReceived);
            }

            this.stats.output +=
              `<h2>Report: ${report.type}</h2>\n<strong>ID:</strong> ${report.id}<br>\n` +
              `<strong>Timestamp:</strong> ${report.timestamp}<br>\n`;

            // Now the statistics for this report; we intentionally drop the ones we
            // sorted to the top above

            Object.keys(report)
              .forEach((statName) => {
                if (
                  statName !== 'id' &&
                  statName !== 'timestamp' &&
                  statName !== 'type'
                ) {
                  this.stats.output += `<strong>${statName}:</strong> ${report[statName]}<br>\n`;
                }
              });
          });
        });
    }, 1000);
  }

  public get isConnected() {
    return this.state?.pc?.connectionState === 'connected' || (!this.playback && this.mediasoup) || this.isLocal;
  }

  // private playVideo() {
  //   const video = this.video;
  //   video.play()
  //     .then(() => {
  //       this.loader = false;
  //       this.started = true;
  //       this.cd.detectChanges();
  //       this.playing.emit();
  //       this.isPlaceholder = false;
  //     })
  //     .catch(error => {
  //       console.error('Error occurred while trying to play the video:', error);
  //     });
  // }

  private setDragging(dragging: boolean) {
    this.store$.dispatch(MultiPlaybackActions.setDragging({ dragging }));
  }

  private playVideo() {
    const TIMEOUT_DURATION = 10000;

    // Function to handle video play
    const handlePlay = () => {
      if (this.videoEventListeners['canplay']) {
        this.video.removeEventListener('canplay', this.videoEventListeners['canplay']);
        delete this.videoEventListeners['canplay'];
      }
      clearTimeout(this.canPlayTimeout);
      this.video.play()
        .then(() => {
          this.loader = false;
          this.started = true;
          this.cd.detectChanges();
          this.playing.emit();
          if (this.isHlsPlayback(this.hlsPlaybackTs)) {
            // this.setMove(0.5, this.hlsPlaybackTs);
          }
          this.setDragging(false);
          this.isPlaceholder = false;
          this.maskTimeUpdate = false;

        })
        .catch(error => {
          console.error('Error occurred while trying to play the video:', error);
        });
    };

    // Check if the video is already playing
    if (!this.video.paused) {
      console.log('Video is already playing.');
      this.loader = false;
      this.started = true;
      this.cd.detectChanges();
      this.playing.emit();
      if (this.isHlsPlayback(this.hlsPlaybackTs)) {
        this.setMove(0.5, this.hlsPlaybackTs + this.video?.currentTime);
      }
      this.setDragging(false);
      this.isPlaceholder = false;
      return;
    }

    // Wait for the video to be ready
    this.videoEventListeners['canplay'] = handlePlay;
    this.video.addEventListener('canplay', this.videoEventListeners['canplay'], { once: true });

    // Set a timeout to handle cases where canplay isn't triggered
    this.canPlayTimeout = setTimeout(() => {
      this.video.removeEventListener('canplay', this.videoEventListeners['canplay']);
      delete this.videoEventListeners['canplay'];
      // Handle fallback logic here
      // this.onError('Playback failed, timeout reached');
    }, TIMEOUT_DURATION);
  }

  private onVideoLoad = () => {
    this.playVideo();
  };

  async createCall() {
    const constraints = { audio: true, video: true };
    if (this.state.pc) {
      this.state?.pc?.close();
      this.state.pc = null;
    }
    this.state.pc = new RTCPeerConnection({ ...this.rtc_configuration });

    this.state.pc.ontrack = ({ track, streams }) => {
      // console.log(`[${this.cameraId}] ontrack`);
      track.onunmute = () => {
        if (!this.placeholder) {
          this.setPlaceholder();
        }
        if (!this.isPlaying) {
          this.state.stream = streams[0];
          this.video.srcObject = streams[0];
          this.video.load();
          this.video.removeEventListener('loadeddata', this.onVideoLoad);
          this.video.addEventListener('loadeddata', this.onVideoLoad);
          if (this.enableHealthCheck && !this.health?.interval) {
            this.startHealthCheck();
          }

          if (this.isExternallyManaged) {
            this.store$.dispatch(WebRtcActiveSessionActions.setActiveSession({
              edgeId: this.edgeId,
              cameraId: this.cameraId,
              sessionState: this.state,
            }));
          }
        }
        if (this.isPlaying) {
          this.isPlaceholder = false;
        } else {
          if (this.placeholderInterval) {
            clearInterval(this.placeholderInterval);
          }
          this.placeholderInterval = setInterval(() => {

            if (this.isPlaying || this.offline) {
              if (!this.offline) {
                this.isPlaceholder = false;
              }
              clearInterval(this.placeholderInterval);
            }
          }, 1000);
        }
      };

    };

    this.state.pc.onconnectionstatechange = (ev) => {
      const prefix = `[WebRTC][${this.sessionId}] `;
      switch (this?.state?.pc?.connectionState) {
        case 'checking':
        case 'connecting':
        case 'new':
          break;
        case 'connected':
          this.playing.emit(null);
          this.qualityChange = false;
          this.loader = false;
          if (!!this.qualitySelector) {
            this.qualitySelector.loading = false;
          }
          break;
        case 'disconnected':
          this.onError('disconnected');
          break;
        case 'closed':
          this.onError('closed');
          break;
        case 'failed':
          this.onError('failed');
          break;
        default:
          break;
      }
    };

    this.state.pc.oniceconnectionstatechange = () => {
      if (this?.state?.pc?.iceConnectionState === 'failed') {
        // this.state.pc.restartIce();
        this.onError('iceConnectionState failed');
      }
    };

    this.state.pc.onicecandidate = async (event) => {
      if (event.candidate == null) {
        return;
      }
      const peerData = {
        ...this.peerDataBase,
        type: WebRtcPeerType.ICE,
        ice: event.candidate.toJSON(),
      };
      await this.publishMsg(peerData);
    };


    this.initStats();
  }

  async onError(reason?: string) {
    this.pause.emit();
    this.setPlaceholder();
    this.errorMsg = reason;
    console.log(reason);
    if (this.hlsPlaybackTs) {
      this.pauseTs = this.hlsPlaybackTs;
    }
    await this.stop();
    this.onStreamError.emit();

    switch (this.liveViewType) {
      case LiveViewType.Livekit:

        break;
      case LiveViewType.Webrtc:
        if (this.state.sessionSubscription) {
          this.state.sessionSubscription.unsubscribe();
        }
        if (this.statsInterval) {
          clearInterval(this.statsInterval);
        }
        this.state?.pc?.close();
        if (this.video.srcObject) {
          delete this.video.srcObject;
          delete this.video.stream;
        }
        this.resetState();
        break;
    }

    if (this.recover && this.recoverCount < MAX_RECOVERIES) {
      this.resetError.emit();
      if (this.playback) {
        this.play({ ts: this.pauseTs });
      } else {
        this.play();
      }
      if (Date.now() - this.lastRecovery < 20000) {
        this.recoverCount++;
      } else {
        this.recoverCount = 0;
      }
      this.lastRecovery = Date.now();
    }
  }

  async retry() {
    this.recoverCount = 0;
    this.resetError.emit();
    this.play();
  }

  async onIncomingSDP(sdp) {
    if (sdp.type === 'offer') {
      await this.state.pc.setRemoteDescription(sdp);
      this.state.receivedOffer = true;
      await this.state.pc.setLocalDescription();
      for(let ice of this.state.incomingIce) {
        await this.onIncomingICE(ice);
      }
      this.state.incomingIce = [];
      const peerData = {
        locationId: this.locationId,
        edgeId: this.edgeId,
        cameraId: this.cameraId,
        timestamp: new Date().getTime(),
        sessionId: this.sessionId,
        type: WebRtcPeerType.SDP,
        sdp: this.state.pc.localDescription.toJSON(),
      };
      await this.publishMsg(peerData);
    }

  };

  async onIncomingICE(ice) {
    // let candidate = new RTCIceCandidate(ice);
    try {
      if (this.forceWebrtcRelay && ice.candidate.includes('relay')) {
        const split = ice.candidate.split(' ');
        const score = +split[3] + 800000000;
        split[3] = score.toString();
        ice.candidate = split.join(' ');
      }

      await this.state.pc.addIceCandidate(ice);
    } catch (err) {
      this.onError('addIceCandidate failed');
    }
  };

  public takeSnapshot(cameraName: string, locationName: string, ts?: number) {
    this.utilsV2Service.takeCameraSnapshot(cameraName, locationName, this.playerObj, ts);
  }

  destroyAbly() {
    try {
      if (this.ablyChannel) {
        this.ablyChannel.unsubscribe();
        this.ablyChannel.detach();
        this.ablyChannel.off();
      }

      if (this.ablyClient) {
        const connectionState = this.ablyClient.connection.state;
        if (connectionState === 'connected' || connectionState === 'connecting') {
          // Wait for a short delay to ensure messages are sent
          setTimeout(() => {
            this.ablyClient.close();
          }, 500);  // Adjust the delay as necessary
        } else if (connectionState !== 'closed') {
          this.ablyClient.close();
        }
      }
    } catch (e: any) {
      console.error('Error during Ably destruction:', e);
    }
  }


  public async ngOnDestroy(): Promise<void> {
    if (this.isLiveKit) {
      this.stop();
    }

    if (this.sessionId && this.playback) {
      this.camerasService.stopWebrtcPlayback(this.edgeId, this.locationId, this.cameraId, this.sessionId)
        .subscribe();
    }

    if (this.hlsPlaybackSessionId) {
      this.stopHlsPlayback();
    }

    if (this.canPlayTimeout) {
      clearTimeout(this.canPlayTimeout);
    }

    if (this.hls) {
      this.hls.destroy();
      this.hls;
      this.hls = null;
    }

    if (this.mediasoupSession?.peer) {
      this.mediasoupService.cleanup(this.mediasoupSession);
    }
    if (this.extendInterval) {
      clearInterval(this.extendInterval);
    }
    if (this.placeholderInterval) {
      clearInterval(this.placeholderInterval);
    }
    if (this.enableHealthCheck) {
      this.clearHealthCheck();
    }
    if (!this.isExternallyManaged) {
      if (this.sessionId) {
        await this.stop();
      }
      if (!!this.state.pc) {
        this.state?.pc?.close();
        this.resetState();
      }
    }
    if (this.statsInterval) {
      clearInterval(this.statsInterval);
    }

    this.destroyAbly();

    if (this.lockService.isLocked(`webrtc-${this.cameraId}`)) {
      this.lockService.releaseLock(`webrtc-${this.cameraId}`);
    }
    if (this.liveStreamControlSubscription) {
      this.liveStreamControlSubscription.unsubscribe();
    }

    if (this.localState?.webSocket) {
      this.localState.webSocket?.close();
      this.playerObj?.nativeElement.pause();
    }
    if (this.playback && this.wsPlayback && this.sessionId) {
      this.leaveRoom();
    }

    this.clearVideo();

    // Remove hls event listeners if any
    if (this.hls) {
      Object.keys(this.hlsEventListeners)
        .forEach((eventKey) => {
          const event = eventKey as keyof HlsListeners;
          const listener = this.hlsEventListeners[event];
          if (listener) {
            this.hls.off(event, listener);
          }
        });
      this.hlsEventListeners = {};

      this.hls.destroy();
      this.hls = null;
    }
  }

  public get isPlaying(): boolean {
    const video = this.video;
    return this.inactive || !!(video && video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2) || (this.localState.videoStarted || this.started) && this.isLocal;
  }

  public openStats() {
    const data: WebrtcDebugDialogData = {
      stats: this.stats,
    };
    this.dialog.open(WebrtcDebugDialogComponent, {
      panelClass: 'modal-no-padding',
      width: '90vw',
      height: '90vh',
      data,
    });
  }

  public cameraSnapshot(cameraId: string): Observable<any> {
    return this.camerasService.getCameraSnapshot(cameraId);
  }

  public healthCheck() {
    // console.log(`[WEBRTC] health check ${this.health?.prevTime} >= ${this.video.currentTime}`);
    if (this.userPause) {
      return;
    }

    if (this.health?.prevTime >= this.video.currentTime) {
      console.log('[WEBRTC] health check failed, sessionId: ' + this.sessionId);
      this.onError('health check failed');
      this.clearHealthCheck();
    }
    this.health.prevTime = this.video?.currentTime;
  }

  public startHealthCheck() {
    if (this.health.interval) {
      clearInterval(this.health.interval);
    }
    this.health.prevTime = this.video?.currentTime;
    this.health.interval = setInterval(() => {
      this.healthCheck();
    }, HEALTHCHECK_INTERVAL_TIME);
  }

  public clearHealthCheck() {
    if (this.health?.interval) {
      clearInterval(this.health?.interval);
    }

    this.health = {
      prevTime: 0,
      interval: null,
    };
  }

  clearMediaSource() {
    if (this.localState?.ms) {
      if (this.localState?.ms.readyState === 'open') {
        this.localState?.ms.endOfStream();
      }
      this.localState.sourceBuffer.removeAllListeners();
      this.localState?.ms.removeAllListeners();
      delete this.localState.ms;
      if (this.localState.sourceBuffer) {
        delete this.localState.sourceBuffer;
      }
    }

  }

  // Set MIME codec and initialize MediaSource
  public setMimeCodec(mc) {
    this.localState.mimeCodec = mc;
    const mimeCodec = this.localState.mimeCodec;
    const succ = MediaSource.isTypeSupported(mimeCodec);
    if (!succ) {
      console.error('Unsupported MIME type or codec: ' + mimeCodec);
      this.fallBackToWebrtc();
      return false;
    }
    this.initMediaSource();
    return succ;
  }

  // Initialize MediaSource
  public initMediaSource() {
    const elementError = (e) => {
      console.log('Media element error');
      const error = e.target.error;
      switch (error.code) {
        case MediaError.MEDIA_ERR_ABORTED:
          console.log('Media playback aborted.');
          break;
        case MediaError.MEDIA_ERR_NETWORK:
          console.log('A network error caused the media download to fail.');
          break;
        case MediaError.MEDIA_ERR_DECODE:
          console.log('The media playback was aborted due to a corruption problem or because the media used features your browser did not support.');
          break;
        case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
          console.log('The media could not be loaded, either because the server or network failed or because the format is not supported.');
          break;
        default:
          console.log('An unknown error occurred.');
          break;
      }
    };

    this.localState.startTimeSet = false;
    this.playerObj.nativeElement.loop = false;

    this.eventListeners['onerror'] = elementError;
    this.eventListeners['canplay'] = () => {
      if (!this.isPlaying) {
        this.playerObj.nativeElement.play();
      }
    };

    this.eventListeners['paused'] = () => {
      console.log('Video paused for buffering...');
      setTimeout(() => {
        this.playerObj.nativeElement.play();
      }, 2000);
    };

    this.addEventListeners(this.playerObj.nativeElement);

    let mimeCodec = this.localState.mimeCodec;

    if (!window.MediaSource) {
      console.error('No Media Source API available');
      return;
    }

    if (!MediaSource.isTypeSupported(mimeCodec)) {
      console.error('Unsupported MIME type or codec: ' + mimeCodec);
      return;
    }

    this.localState.ms = new MediaSource();
    const ms = this.localState.ms;
    this.playerObj.nativeElement.src = window.URL.createObjectURL(ms);

    ms.addEventListener('sourceopen', () => {
      console.log('sourceopen');
      this.localState.sourceBuffer = ms.addSourceBuffer(mimeCodec);
      if (this.localState.playbackOptionSequence || this.wsPlayback) {
        // For playback over websocket we must use sequence since we are changing files.
        this.localState.sourceBuffer.mode = 'sequence';
      }
      this.localState.sourceBuffer.addEventListener('updateend', () => this.processQueue());
      this.localState.sourceBuffer.addEventListener('error', (event) => {
        console.log('Media source error');

      });
      this.processQueue(); // Process the queue immediately after source buffer is open
    });
  }

  // Process the queue and append to buffer
  private processQueue() {
    const sb = this.localState?.sourceBuffer;
    if (!sb) {
      return;
    }
    if (sb?.updating) {
      return;
    }
    if (!this.wsPlayback) {
      try {
        if (sb?.buffered && sb?.buffered?.length) {
          const end = sb.buffered.end(sb.buffered.length - 1);
          const start = sb.buffered.start(0);
          if (end - 15 > start) {
            sb.remove(start, end);
            console.log(start);
            return;
          }
        }
      } catch (e) {
        console.error('Error removing from buffer', e);
      }
    }

    if (sb && this.localState.queue.length > 0) {
      const data = this.localState.queue.shift();
      if (this.playerObj.nativeElement.error) {
        console.error('Media element is in error state, aborting append');
        if (this.localState.errorCounter < 4) {
          const counter = this.localState.errorCounter++;
          this.stop();
          this.play();

          this.localState.errorCounter = counter;
        } else {
          this.fallBackToWebrtc();
        }
        return;
      }
      this.appendToBuffer(data);
    }
  }

  // Append video data to source buffer
  private appendToBuffer(videoChunk) {
    const video: HTMLVideoElement = this.playerObj.nativeElement;
    if (videoChunk && this.localState?.sourceBuffer) {
      try {
        const buffLen = video?.buffered?.length;
        this.localState.sourceBuffer.appendBuffer(videoChunk);
        if (buffLen > 0) {
          // Get the end time of the last buffered range
          const start = video.buffered.start(0);
          const end = video.buffered.end(buffLen - 1);
          this.isPlaceholder = false;
          if (!video.currentTime || this.wsShouldReset) {
            video.currentTime = start;
            video.play();
            this.isPlaceholder = false;
            this.localState.lastBound = end;
            if (this.wsShouldReset) {
              this.wsShouldReset = false;
            }
          }
          if (!this.wsPlayback) {
            if (end > 0 && end - this.localState.lastBound > 5) {
              // Now you can safely seek to the end of the video buffer
              video.currentTime = end;
              this.localState.lastBound = end;
            }
          }
        }
        this.localState.startTimeSet = true;
        // }
      } catch (error) {
        console.error('Failed to append buffer', error);
        this.localState.queue.unshift(videoChunk); // Requeue the data if append failed
      }
    }
  }

  public onWebsocketMessage(messageEvent) {
    let wsMsg = messageEvent;

    if (typeof wsMsg === 'string') {
      // if (wsMsg.indexOf('mimecodec:') === 0) {
      //   this.setMimeCodec(wsMsg.split(':')[1]);
      //   console.log('Got MIME codec ' + this.localState?.mimeCodec);
      // }
    } else {
      var arrayBuffer;
      var fileReader = new FileReader();
      fileReader.onload = (event) => {
        arrayBuffer = event.target.result;
        var data = new Uint8Array(arrayBuffer);
        // console.log('received: ' + data.length + ' bytes');
        if (!this.localState.streamingStarted) {
          this.localState.streamingStarted = true;
          this.localState.videoStarted = true;
          this.isPlaceholder = false;
          this.playing.emit(null);
        }
        this.localState.queue.push(arrayBuffer);
        this.processQueue(); // Process the queue after pushing the data
      };
      fileReader.readAsArrayBuffer(wsMsg);

    }
  };

  // Open a new WebSocket connection using the given parameters
  private openWSConnection(protocol: string, hostname: string, port: number, play = false) {

    var webSocketURL = null;
    var keepAliveCount = 0;

    webSocketURL = protocol + '://' + hostname + '/livews' + port;
    // webSocketURL = 'ws://192.168.100.116:' + port;
    console.log('openWSConnection::Connecting to: ' + webSocketURL);

    const offline = `<h4><span class="badge bg-danger">Disconnected</span></h4>`;
    const online = `<h4><span class="badge bg-success">Connected</span></h4>`;

    try {
      this.localState.webSocket = new WebSocket(webSocketURL);
      this.localState.webSocket.debug = true;
      this.localState.webSocket.timeoutInterval = 3000;
      this.localState.sessionId = uuid.v4();
      this.localState.webSocket.onopen = (openEvent) => {
        var open = JSON.stringify(openEvent, null, 4);
        console.log('WebSocket open');
        this.localState?.webSocket?.send('sessionid:' + this.localState.sessionId);
      };
      this.localState.webSocket.onclose = (closeEvent) => {
        var closed = JSON.stringify(closeEvent, null, 4);
        console.log('WebSocket closed');
        this.playerObj.nativeElement.pause;
        this.fallBackToWebrtc();
      };
      this.localState.webSocket.onerror = (errorEvent) => {
        var error = JSON.stringify(errorEvent, null, 4);
        console.log('WebSocket ERROR: ' + error);
        this.fallBackToWebrtc();
      };
      this.localState.webSocket.onmessage = (messageEvent) => {
        var wsMsg = messageEvent.data;
        if (typeof wsMsg === 'string') {
          if (wsMsg.indexOf('mimecodec:') === 0) {
            this.setMimeCodec(wsMsg.split(':')[1]);
            console.log('Got MIME codec ' + this.localState?.mimeCodec);
          } else if (wsMsg.indexOf('max_connections') === 0) {
            console.log('max sessions reached');
            this.fallBackToWebrtc();
          }
        } else {
          var arrayBuffer;
          var fileReader = new FileReader();
          fileReader.onload = (event) => {
            arrayBuffer = event.target.result;
            var data = new Uint8Array(arrayBuffer);
            if (this.localState?.enableVerboseMsgLog) {
              console.log('received: ' + data.length + ' bytes');
            }
            if (!this.localState.streamingStarted) {
              this.localState.streamingStarted = true;
              this.localState.videoStarted = true;
              this.isPlaceholder = false;
              this.playing.emit(null);
            }
            this.localState.queue.push(arrayBuffer);
            this.processQueue(); // Process the queue after pushing the data
          };
          fileReader.readAsArrayBuffer(wsMsg);

          // Keep-alive message
          keepAliveCount++;
          if (keepAliveCount >= 10 && this.localState?.webSocket?.readyState === WebSocket.OPEN) {
            keepAliveCount = 0;
            this.localState?.webSocket?.send('keep-alive');
          }
        }
      };
    } catch (exception) {
      console.error(exception);
      this.fallBackToWebrtc();
    }
  }

  private clearVideo(): void {
    const videoElement = this.playerObj.nativeElement;
    if (videoElement) {
      // Remove video event listeners
      Object.keys(this.videoEventListeners)
        .forEach(event => {
          videoElement.removeEventListener(event, this.videoEventListeners[event]);
        });
      this.videoEventListeners = {};

      videoElement.pause();
      videoElement.src = '';
      videoElement.load(); // Reset the video element
      videoElement.srcObject = null;

      // Remove from DOM if necessary
      // this.playerObj.nativeElement.parentNode.removeChild(videoElement);
    }
  }

  private addEventListeners(element: HTMLElement): void {
    Object.keys(this.eventListeners)
      .forEach(event => {
        element.addEventListener(event, this.eventListeners[event]);
      });
  }

  private removeAllEventListeners(): void {
    const videoElement = this.playerObj.nativeElement;
    Object.keys(this.eventListeners)
      .forEach(event => {
        videoElement.removeEventListener(event, this.eventListeners[event]);
      });
  }

  public enableWSPlaybackTest() {
    this.isLocal = true;
    this.localLiveStreamEnabled = true;
  }

  public async liveKitDisconnect(): Promise<void> {
    await this.livekitService.disconnect(this.liveKitSession?.room);
  }

  public async liveViewTypeChange(liveViewType: LiveViewType) {
    await this.stop();
    this.liveViewType = liveViewType;

    this.play();
  }

  public async pauseVideo(user = false, paused = false, currentTs?: number) {
    this.clearHealthCheck();
    this.paused = paused;
    this.userPause = user;
    this.pauseTs = currentTs;
    this.video?.pause();
  }

  public showParticipants(isDeveloper: boolean) {
    const data: UiParticipantsDialogData = {
      edgeId: this.edgeId,
      cameraId: this.cameraId,
      showPublisher: isDeveloper,
    };
    this.dialog.open(UiParticipantsDialogComponent, {
      panelClass: 'modal-no-padding',
      data,
    });
  }

  // Update the video time
  public onTimeUpdate(event: Event): void {
    if (this.maskTimeUpdate || this.dragging) {
      return;
    }
    const currentTime = this.video.currentTime;
    if (this.hlsPlaybackTs && this.isHlsPlayback(this.hlsPlaybackTs)) {
      const lastFiveMinutes = Date.now() - 5 * 60 * 1000;
      if (currentTime + 0.1 >= this.hlsPlaybackDuration && this.hlsPlaybackTs < lastFiveMinutes) {
        // console.log('End of HLS playback');
        this.setMove(0.5, this.hlsPlaybackTs + (currentTime + 2) * 1000);
      } else {
        // console.log('HLS playback ts: ' + this.hlsPlaybackTs);
        // console.log('Current time: ' + currentTime);
        this.setMove(0.5, this.hlsPlaybackTs + currentTime * 1000);
      }
    }
  }

  setMove(percentage: number, timestamp: number) {
    this.store$.dispatch(MultiPlaybackActions.setMove({ move: { percentage, timestamp } }));
  }
}
