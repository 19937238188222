import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { debounceTime, filter, finalize, interval, lastValueFrom, Observable, Subject, Subscription, switchMap, take, takeWhile } from 'rxjs';
import { MultiPlaybackSelectors } from '@states/multi-playback/multi-playback.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EdgeCamera } from '../../cameras/camera.model';
import { select, Store } from '@ngrx/store';
import { CdkDrag, CdkDragEnd, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { ThumbnailHistogramColor } from '@consts/thumbnail.const';
import { formatDate } from '@angular/common';
import { TimelineDragEvent } from './timeline-series/timeline-series.component';
import { MultiPlaybackMove } from '@models/multi-playback.model';
import { MediaCacheService } from '../media-cache/media-cache.service';
import { PLAYBACK_OFFLINE_INTERVAL_MS } from '../playback-player/playback-player.component';
import { UiThumbnailPreviewData } from '../ui-kit/ui-thumbnail-preview/ui-thumbnail-preview.component';
import { ThumbnailHistogramComponent } from '../thumbnail-histogram/thumbnail-histogram.component';
import { EventsHistogramComponent } from '../events-histogram/events-histogram.component';
import { StorageHistogramComponent } from '../storage-histogram/storage-histogram.component';
import { UIInputStyle } from '@enums/shared.enum';
import moment from 'moment';
import { CamerasThumbnailsService } from '../../cameras/camera-thumbnails/camera-thumnails.service';
import { StorageSelectors } from '@states/storage/storage.selector-types';
import { ThumbnailsSelectors } from '@states/thumbnails/thumbnails.selector-types';
import { PermissionSelectors } from '@states/permissions/permissions.selector-types';
import Permissions = PermissionModel.Permissions;
import { PermissionModel } from '@models/permission.model';
import { WallModelV2 } from '@models/wall.model';
import { WallLayoutCameraCount } from '../../user/user.model';
import { DAY_IN_MSECS } from '../../development/stats.service';

@UntilDestroy()
@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('timelineWrapper') timelineWrapper: ElementRef;
  @ViewChild('timeline') timeline: ElementRef;
  @ViewChild('scroller') scroller: ElementRef;
  @ViewChild('mouseNeedle') mouseNeedle: ElementRef;
  @ViewChild('thumbPrev') thumbPrev: ElementRef;
  @ViewChild('scrollerDrag') dragRef: CdkDrag;

  @ViewChild('thumbnailHistogram') thumbnailHistogram: ThumbnailHistogramComponent;
  @ViewChild('eventsHistogram') eventsHistogram: EventsHistogramComponent;
  @ViewChild('storageHistogram') storageHistogram: StorageHistogramComponent;

  @Input() isPlayback = false;
  @Input() initTs: number;
  @Input() isCameraView = false;
  @Input() streamLiveView = false;

  scrollerPosition = 0;

  public selectedCamerasCount$: Observable<number> = this.store$.select(MultiPlaybackSelectors.selectSelectedCamerasCount)
    .pipe(untilDestroyed(this));

  public selectCameraEvents$: Observable<number[][]> = this.store$.select(MultiPlaybackSelectors.selectCameraEvents)
    .pipe(untilDestroyed(this));

  public selectCameraStorageStats$: Observable<number[][]> = this.store$.select(MultiPlaybackSelectors.selectCameraStorageStats)
    .pipe(untilDestroyed(this));

  public selectedPositions$: Observable<number[]> = this.store$.select(MultiPlaybackSelectors.selectPositions)
    .pipe(untilDestroyed(this));

  public selectSelectedCameras$: Observable<EdgeCamera.CameraItem[]> = this.store$.select(MultiPlaybackSelectors.selectSelectedCameras)
    .pipe(untilDestroyed(this));

  public selectMove$: Observable<MultiPlaybackMove> = this.store$.select(MultiPlaybackSelectors.selectMove)
    .pipe(untilDestroyed(this));

  @Output() timeChanged = new EventEmitter<TimelineDragEvent>();
  @Output() timelineDragEnd = new EventEmitter<TimelineDragEvent>();
  @Output() timelineDragStart = new EventEmitter<void>();
  @Output() seekStart = new EventEmitter<void>();
  @Output() seekEnd = new EventEmitter<TimelineDragEvent>();
  @Output() pause = new EventEmitter<void>();
  @Output() play = new EventEmitter<void>();
  @Output() toggleToLiveView = new EventEmitter<void>();
  @Output() showCreateArchiveModal = new EventEmitter<void>();

  public ThumbnailHistogramColor = ThumbnailHistogramColor;

  public WallLayout: typeof WallModelV2.WallLayout = WallModelV2.WallLayout;
  @Input() layout = this.WallLayout.GRID_1;
  @Input() time = 0;
  @Input() videoCurrentTime = 0;
  @Input() rtl = false;
  @Input() searchEvents: number[][];

  @Input() startTime;
  @Input() endTime;
  @Input() timezone = 'GMT';
  @Input() zoomEnabled = true;
  @Input() events: number[];
  @Input() videoSection = false;

  @Input() fixedRange = false;

  @Input() isPlaying: boolean;
  @Input() isAlert: boolean = false;

  controlDebouncer: Subject<void> = new Subject<void>();

  public dragging = false;
  dragged = false;

  date = '';

  start: number = new Date().getTime();
  end: number = new Date().getTime() + 1000 * 60 * 60;

  displayCount = WallModelV2.wallLayoutCameraCountV2[WallModelV2.WallLayout.GRID_1];
  displayIndexes = [...Array(this.displayCount)
    .keys()];

  percent = 0;
  ts = 0;

  pickerDate: Date;

  primaryEdgeId: string;
  primaryCameraId: string;

  public previewData: UiThumbnailPreviewData;
  public previewTs: number;


  private mouseDownClickTime: number;
  private readonly shortClickDuration = 200;

  emulateSub: Subscription;
  noMore = false;
  isEmulating = false;

  public isNow = false;
  private init = false;
  public nowDate = new Date();

  public maskMove = false;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private camerasThumbnailsService: CamerasThumbnailsService,
    private store$: Store, private renderer: Renderer2, private mediaCacheService: MediaCacheService, public cd: ChangeDetectorRef) {
  }

  public get position() {
    return (this.ts - this.start) / (this.end - this.start);
  }

  public resize() {
    this.renderScrollerPosition();
    this.redrawAll(true);
  }

  public get now() {
    return moment(this.ts)
      .toDate();
  }

  public get nowTs() {
    return Date.now();
  }

  public get datePickerEnd() {
    return moment(this.ts)
      .add(2, 'minutes')
      .toDate();
  }

  public get isCreateArchivePermission(): Observable<boolean> {
    return this.store$.pipe(select(PermissionSelectors.checkAccessPermissions([Permissions.ArchiveCreate], [])),
      take(1));
  }


  ngAfterViewInit(): void {
    if (this.initTs) {
      this.ts = this.initTs;
      this.percent = (this.ts - this.startTime) / (this.endTime - this.startTime);
      this.renderScrollerPosition();
    }
  }

  ngOnDestroy(): void {
    if (!!this.emulateSub) {
      this.emulateSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (this.startTime) {
      this.start = this.startTime;
    }
    if (this.endTime) {
      this.end = this.endTime;
    }


    this.setDateDisplay();

    // STORAGE STATS SQS IMPLEMENTATION
    this.selectSelectedCameras$.pipe(untilDestroyed(this))
      .subscribe(async (cameras) => {
        if (!cameras?.length) {
          return;
        }
        this.primaryEdgeId = cameras[0]?.edgeId;
        this.primaryCameraId = cameras[0]?.edgeOnly?.cameraId ?? cameras[0]?.cameraId;
        this.previewData = {
          edgeId: this.primaryEdgeId,
          cameraId: this.primaryCameraId,
        };
      });


    this.selectMove$.subscribe(async (move) => {
      if (this.maskMove || this.dragging) {
        return;
      }
      const prevTs = this.ts;
      this.ts = Math.floor(move.timestamp);
      const delta = this.ts - prevTs;
      this.percent = this.isCameraView ? 0.5 : this.position;//move.percentage;
      if (this.percent >= 0.99 || this.isCameraView && !this.streamLiveView) {
        this.start += delta;
        this.end += delta;
        const event: TimelineDragEvent = {
          start: this.start,
          end: this.end,
          ts: this.ts,
        };
        this.timeChanged.emit(event);
      } else {
        this.renderScrollerPosition();
      }
    });

    this.controlDebouncer.pipe(debounceTime(1000))
      .subscribe(() => {
        const event: TimelineDragEvent = {
          start: this.start,
          end: this.end,
          ts: this.ts,
        };
        this.timelineDragEnd.emit(event);
        this.timeChanged.emit(event);
        this.setMove(this.percent, this.ts);
        this.setDateDisplay();
        this.setDragging(true);
        this.maskMove = false;
      });

    this.isNow = Date.now() - this.startTime < 5 * 1000 * 60; // 5 minutes
    if (this.isAlert && this.isNow) {
      this.events = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    }
  }

  public async isOffline(time?: number) {
    const offline = await this.mediaCacheService.isOffline(this.primaryEdgeId, this.primaryCameraId, time ?? this.ts);
    return offline;
  }


  setDateDisplay() {
    this.date = formatDate(this.start, 'EEE, MMMM d, y', 'en-US');
    const endDate = formatDate(this.end, 'EEE, MMMM d, y', 'en-US');
    if (this.date !== endDate) {
      const start = formatDate(this.start, 'EEE, MMMM d - ', 'en-US');
      const end = formatDate(this.end, 'd, y', 'en-US');
      this.date = start + end;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['layout']) {
      this.onChangeLayout();
    }

    if (changes['videoCurrentTime']) {
      if (this.dragging) {
        return;
      }
      if (changes['videoCurrentTime'].currentValue === 0) {
        return;
      }
      const delta = changes['videoCurrentTime'].previousValue && changes['videoCurrentTime'].currentValue !== 0 ?
        changes['videoCurrentTime'].currentValue - changes['videoCurrentTime'].previousValue : 0;

      const offset = (this.end - this.start) * this.percent;

      const timestamp = this.ts + delta;
      this.percent = this.isCameraView ? 0.5 : (timestamp - this.start) / (this.end - this.start);

      this.store$.dispatch(MultiPlaybackActions.setMove({ move: { percentage: 0.5, timestamp } }));

      if (this.percent >= 0.99 || this.isCameraView) {
        this.start += delta;
        this.end += delta;
        const event: TimelineDragEvent = {
          start: this.start,
          end: this.end,
          ts: timestamp,
        };
        this.timeChanged.emit(event);
      } else {
        this.renderScrollerPosition();
      }
    }

    if (changes['startTime'] || changes['endTime']) {
      if (this.dragging) {
        return;
      }
      if (this.startTime) {
        this.start = this.startTime;
      }
      if (this.endTime) {
        this.end = this.endTime;
      }
      this.isNow = Date.now() - this.startTime < 5 * 1000 * 60; // 5 minutes

      this.setDateDisplay();
    }
  }

  onChangeLayout() {
    const layout = this.layout;
    this.displayCount = WallLayoutCameraCount[layout];
    this.displayIndexes = [...Array(this.displayCount)
      .keys()];
  }

  timeDragStart(event: CdkDragStart) {
    this.dragging = true;
    // this.setDragging(true);
    this.seekStart.emit();
  }

  timeDragEnd(event: CdkDragEnd) {
    const timestamp = this.start + (this.end - this.start) * this.percent;
    this.ts = timestamp;
    this.setMove(this.percent, timestamp);
    this.renderScrollerPosition();
    this.seekEnd.emit({
      start: this.start,
      end: this.end,
      ts: timestamp,
    });
    this.dragging = false;
    // this.setDragging(false);
  }

  timeClickDown(event: MouseEvent) {
    this.mouseDownClickTime = Date.now();
  }

  timeClickUp(event: MouseEvent) {
    if (this.isCameraView) {
      return;
    }
    const mouseUpTime = Date.now();
    const clickDuration = mouseUpTime - this.mouseDownClickTime;

    if (clickDuration >= this.shortClickDuration) {
      return;
    }

    const timelineRect = this.timeline.nativeElement.getBoundingClientRect();
    const startPosition = timelineRect.x;
    const width = timelineRect.width;
    const offset = Math.max(event.x - startPosition, 0);
    const percent = offset / width;
    this.percent = this.isCameraView ? 0.5 : percent;
    const timestamp = this.start + (this.end - this.start) * (this.isCameraView ? 0.5 : percent);
    this.setMove(percent, timestamp);
    this.renderScrollerPosition();
    this.seekEnd.emit({
      start: this.start,
      end: this.end,
      ts: timestamp,
    });

  }

  timeDragMove(event: CdkDragMove) {
    const timelineRect = this.timeline.nativeElement.getBoundingClientRect();
    const startPosition = timelineRect.x;
    const width = timelineRect.width;
    const offset = Math.max(event.pointerPosition.x - startPosition, 0);
    const percent = offset / width;
    this.percent = this.isCameraView ? 0.5 : percent;
    const timestamp = this.start + (this.end - this.start) * this.percent;
    this.setMove(this.isCameraView ? 0.5 : percent, timestamp);
  }

  setMove(percentage: number, timestamp: number) {
    this.store$.dispatch(MultiPlaybackActions.setMove({ move: { percentage, timestamp } }));
  }


  _timelineDrag(event: TimelineDragEvent) {
    if (!this.dragged) {
      this.dragged = true;
    }

    const start = event.start;
    const end = event.end;
    const timestamp = start + (end - start) * this.percent;
    const now = Date.now();
    if (this.isCameraView && timestamp > now) {
      return;
    }
    this.start = start;
    this.end = end;
    this.ts = timestamp;
    this.setMove(this.percent, timestamp);
    event.ts = timestamp;
    this.setDateDisplay();
    this.timeChanged.emit(event);
  }

  // storageStats(edgeId: string, cameraId: string) {
  //   return this.store$.select(StorageSelectors.selectStorageEventsByEdgeIdCameraIdAndRange({
  //     edgeId,
  //     cameraId,
  //     start: this.start,
  //     end: this.end,
  //   }));
  // }

  resetPosition() {
    this.dragRef._dragRef.reset();
  }

  renderScrollerPosition() {
    if (this.scroller) {
      const timelineRect = this.timeline.nativeElement.getBoundingClientRect();
      const width = timelineRect.width;
      const percent = Math.max(this.percent, 0);
      if (!!this.dragRef && this.dragRef?._dragRef) {
        this.dragRef?._dragRef?.setFreeDragPosition({ x: percent * width, y: 0 });
      }
      this.renderer.setStyle(this.scroller?.nativeElement, 'transform', 'translate3d(' + percent * width + 'px, 0px, 0px)');
    }
  }

  public moveTimeline(delta: number) {
    this.ts += delta;
    this.start += delta;
    this.end += delta;
  }

  public emulatePlayback() {
    this.isEmulating = true;
    if (this.emulateSub) {
      this.emulateSub.unsubscribe();
    }
    this.emulateSub = interval(PLAYBACK_OFFLINE_INTERVAL_MS)
      .pipe(
        switchMap(async _ => {
          const offline = await this.isOffline(this.ts);
          return offline || this.noMore;
        }),
        takeWhile(offline => !!offline && this.nowTs - this.ts > 5 * 60 * 1000),
        finalize(() => {
          this.noMore = false;
          this.isEmulating = false;
        }),
      )
      .subscribe(() => {
        if (this.dragging) {
          return;
        }
        this.ts += PLAYBACK_OFFLINE_INTERVAL_MS;
        if (this.percent >= 1) {
          this.start += PLAYBACK_OFFLINE_INTERVAL_MS;
          this.end += PLAYBACK_OFFLINE_INTERVAL_MS;
          const event: TimelineDragEvent = {
            start: this.start,
            end: this.end,
            ts: this.ts,
          };
          this.timeChanged.emit(event);
        } else {
          this.percent = this.isCameraView ? 0.5 : (this.ts - this.start) / (this.end - this.start);
          if (!this.isCameraView) {
            this.renderScrollerPosition();
          } else {
            const delta = this.end - this.start;
            this.start = this.ts - delta / 2;
            this.end = this.ts + delta / 2;
            this.controlDebouncer.next();
          }
          this.setMove(this.percent, this.ts);
        }
      });
  }

  moveMouseNeedle(event: MouseEvent) {
    const timelineRect = this.timeline.nativeElement.getBoundingClientRect();
    const startPosition = timelineRect.x;
    const width = timelineRect.width;
    const offset = Math.max(event.x - startPosition, 0);
    const percent = offset / width;
    this.previewTs = this.start + (this.end - this.start) * percent;
    if (this.previewTs >= this.nowTs) {
      this.renderer.setStyle(this.thumbPrev.nativeElement, 'display', 'none');
      return;
    }
    const onRight = this.timeline.nativeElement.getBoundingClientRect().right - event.x < this.thumbPrev.nativeElement.clientWidth / 2 + 6;
    if (onRight) {
      const left = this.timeline.nativeElement.getBoundingClientRect().right - event.x - this.thumbPrev.nativeElement.clientWidth / 2 - 6;
      this.renderer.setStyle(this.thumbPrev.nativeElement, 'transform', 'translateX(calc(-50% + ' + left + 'px))');
    } else {
      this.renderer.setStyle(this.thumbPrev.nativeElement, 'transform', 'translateX(-50%)');
    }
    this.renderer.setStyle(this.mouseNeedle.nativeElement, 'opacity', 1);
    this.renderer.setStyle(this.mouseNeedle.nativeElement, 'transform', 'translate3d(' + percent * width + 'px, 0px, 0px)');
    this.renderer.setStyle(this.thumbPrev.nativeElement, 'display', 'block');
  }

  resetMouseNeedle() {
    this.renderer.setStyle(this.thumbPrev.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.mouseNeedle.nativeElement, 'opacity', 0);
    this.renderer.setStyle(this.mouseNeedle.nativeElement, 'transform', 'translate3d(0px, 0px, 0px)');
  }

  backward(delta = 10000) {
    this.maskMove = true;

    // this.pause.emit();
    this.ts = this.ts - delta;
    if (this.ts < this.start) {
      const delta = this.start - this.ts;
      this.end -= delta;
      this.start -= delta;
    }
    if (!this.isCameraView) {
      this.percent = this.isCameraView ? 0.5 : Math.min((this.ts - this.start) / (this.end - this.start), 1);
      this.percent = this.isCameraView ? 0.5 : Math.max(this.percent, 0);
      this.renderScrollerPosition();
      console.log(this.ts);
      this.setMove(this.percent, this.ts);
      this.controlDebouncer.next();
    } else {
      const delta = this.end - this.start;
      this.start = this.ts - delta / 2;
      this.end = this.ts + delta / 2;
      this.controlDebouncer.next();
    }
  }

  forward(delta = 10000) {
    this.maskMove = true;
    // this.pause.emit();
    this.ts = this.ts + delta;
    if (this.ts > this.end) {
      const delta = this.ts - this.end;
      this.end += delta;
      this.start += delta;
    }

    if (!this.isCameraView) {
      this.percent = this.isCameraView ? 0.5 : (this.ts - this.start) / (this.end - this.start);
      this.renderScrollerPosition();
      this.setMove(this.percent, this.ts);
      if (this.videoSection) {
        this.controlDebouncer.next();
      }
    } else {
      const delta = this.end - this.start;
      this.start = this.ts - delta / 2;
      this.end = this.ts + delta / 2;
      this.controlDebouncer.next();
    }

  }

  private setDragging(dragging: boolean) {
    this.store$.dispatch(MultiPlaybackActions.setDragging({ dragging }));
  }

  async checkTimelineData(currentBase: number, nextBase: number, nextTs: number, start?: number, end?: number) {
    const delta = this.end - this.start;
    start = start ?? nextTs - delta / 2;
    end = end ?? nextTs + delta / 2;
    const baseStart = this.getBaseInLocale(new Date(start));
    const baseEnd = baseStart + DAY_IN_MSECS;
    if (currentBase !== nextBase) {
      await this.mediaCacheService.getThumbnails([{ edgeId: this.primaryEdgeId, cameraId: this.primaryCameraId }], baseStart, baseEnd - 20000);
      await this.mediaCacheService.getStorageStats([{ edgeId: this.primaryEdgeId, cameraId: this.primaryCameraId }], baseStart, baseEnd - 20000);
      await lastValueFrom(this.checkStorage(nextBase));
      await lastValueFrom(this.checkThumbnails(nextBase));
    }
  }

  async prevAlert() {
    this.maskMove = true;
    // this.pause.emit();
    const origTs = this.ts;
    const prevTs = await this.mediaCacheService.getPreviousAlertTimestamp(this.primaryEdgeId, this.primaryCameraId, this.ts, this.videoSection);
    if (!prevTs) {
      return;
    }
    this.ts = prevTs;

    const currentBase = this.getBaseInLocale(new Date(origTs));
    const nextBase = this.getBaseInLocale(new Date(this.ts));
    if (this.isCameraView) {
      const delta = this.end - this.start;
      this.start = this.ts - delta / 2;
      this.end = this.ts + delta / 2;
      await this.checkTimelineData(currentBase, nextBase, this.ts);
      this.controlDebouncer.next();
      return;
    }
    if (this.ts < this.start) {
      // const delta = this.start - this.ts;
      // this.end -= delta;
      // this.start -= delta;
      const delta = this.end - this.start;
      this.start = this.ts - delta / 2;
      this.end = this.ts + delta / 2;
      await this.checkTimelineData(currentBase, nextBase, this.ts, this.start, this.end);
      this.percent = 0.5;
      this.percent = 0.5;
    } else {
      this.percent = this.isCameraView ? 0.5 : Math.min((this.ts - this.start) / (this.end - this.start), 1);
      this.percent = this.isCameraView ? 0.5 : Math.max(this.percent, 0);
    }


    this.renderScrollerPosition();
    this.setMove(this.percent, this.ts);

    this.controlDebouncer.next();
  }

  public async nextAlert() {
    this.maskMove = true;
    // this.pause.emit();
    const origTs = this.ts;
    const nextTs = await this.mediaCacheService.getNextAlertTimestamp(this.primaryEdgeId, this.primaryCameraId, this.ts, this.videoSection);
    if (!nextTs) {
      return;
    }
    this.ts = nextTs;
    const currentBase = this.getBaseInLocale(new Date(origTs));
    const nextBase = this.getBaseInLocale(new Date(this.ts));

    if (this.isCameraView) {
      const delta = this.end - this.start;
      this.start = this.ts - delta / 2;
      this.end = this.ts + delta / 2;
      await this.checkTimelineData(currentBase, nextBase, this.ts);
      this.controlDebouncer.next();
      return;
    }
    if (this.ts > this.end) {
      // const delta = this.ts - this.end;
      // this.end += delta;
      // this.start += delta;
      // await this.checkTimelineData(currentBase, nextBase, this.ts, this.start, this.end);
      const delta = this.end - this.start;
      this.start = this.ts - delta / 2;
      this.end = this.ts + delta / 2;
      await this.checkTimelineData(currentBase, nextBase, this.ts, this.start, this.end);
      this.percent = 0.5;
      this.percent = 0.5;
    } else {
      this.percent = this.isCameraView ? 0.5 : (this.ts - this.start) / (this.end - this.start);
    }
    this.renderScrollerPosition();
    this.setMove(this.percent, this.ts);
    if (this.videoSection) {
      this.controlDebouncer.next();
    }
  }

  public redrawAll(resize = false) {
    if (this.searchEvents) {
      this.eventsHistogram.redraw();
    }
    this.thumbnailHistogram?.render(resize);
    this.storageHistogram?.render(resize);
  }

  public onPickerOpen() {
    // this.pickerDate = this.now;
  }

  getBaseInLocale(date: Date) {
    return this.camerasThumbnailsService.getBaseInLocale(date, this.timezone);
  }

  public checkStorage(base: number) {
    return this.store$.select(
        StorageSelectors.selectStorageEventsByEdgeIdCameraIdAndBase({ edgeId: this.primaryEdgeId, cameraId: this.primaryCameraId, base }),
      )
      .pipe(filter(storage => !!storage), take(1));
  }

  public checkThumbnails(base: number) {
    return this.store$.select(
        ThumbnailsSelectors.selectEventsByEdgeIdCameraIdAndBase({ edgeId: this.primaryEdgeId, cameraId: this.primaryCameraId, base }),
      )
      .pipe(filter(thumbnails => !!thumbnails), take(1));
  }

  public async changeDateRange(event, emit = true): Promise<void> {
    if (emit) {
      this.maskMove = true;
      this.setDragging(true);
    }
    this.pause.emit();
    const currentBase = this.getBaseInLocale(new Date(this.ts));
    const dateTimePart = event?.start?.split(' ')[0] + ' ' + event?.start?.split(' ')[1];
    const inputFormat = 'YYYY-MM-DD HH:mm:ss';
    const userTime = moment.tz(dateTimePart, inputFormat, this.timezone);
    const ts = userTime.valueOf();
    const delta = this.end - this.start;
    const start = ts - delta / 2;
    const end = ts + delta / 2;
    const nextBase = this.getBaseInLocale(event.start);
    await this.checkTimelineData(currentBase, nextBase, ts);
    this.start = start;
    this.end = end;
    this.ts = ts;

    this.setMove(this.percent, this.ts);
    if (emit) {
      this.controlDebouncer.next();
    }
    this.maskMove = false;

    setTimeout(() => {
      this.redrawAll();
    });

  }

  _timelineDragEnd(event: TimelineDragEvent) {
    const _event: TimelineDragEvent = {
      start: this.start,
      end: this.end,
      ts: this.ts,
    };
    this.timelineDragEnd.emit(_event);
  }


  public readonly uiInputStyles = UIInputStyle;
}
