<ng-container *ngrxLet="selectSelectedCameras$; let selectedCameras">
  <ng-container *ngrxLet="selectCameraEvents$; let cameraEvents">
    <ng-container *ngrxLet="selectedCamerasCount$; let camerasCount">
      <ng-container *ngrxLet="selectedPositions$; let positions">
        <div #timelineWrapper
             fxLayout="row"
             fxLayoutAlign="start start"
             class="w-100 time-series-wrapper"
        >
          <div fxLayout="column" fxLayoutAlign="start center" class="spacer" *ngIf="!isCameraView">
          </div>
          <div fxLayout="column"
               fxLayoutAlign="start center"
               class="time-series w-100"
               [class.live-view]="isCameraView"
          >

            <div fxLayoutAlign="center center" class="w-100 time-series-date p-r" [class.live-view]="isCameraView">
              <div class="playback-actions flex-row start-center h-100"
                   [class.camera-view]="isCameraView"
                   [class.gap-8]="isCameraView"
              >
                <ng-container *ngIf="videoSection; else imageSection">
                  <span matRipple
                        matTooltip="Backward 10 seconds"
                        class="ui-icon-backward-10"
                        (click)="backward()"
                  ></span>
                  <span matRipple matTooltip="Forward 10 seconds" class="ui-icon-forward-10" (click)="forward()"></span>
                </ng-container>
                <ng-template #imageSection>
                  <span matRipple class="ui-icon-prev-frame" (click)="backward(2000)"></span>
                  <span matRipple class="ui-icon-next-frame" (click)="forward(2000)"></span>
                </ng-template>
                <span *ngIf="!isPlaying"
                      matRipple
                      matTooltip="Play"
                      class="ui-icon-play"
                      [class.primary]="isCameraView"
                      (click)="play.emit()"
                ></span>
                <span *ngIf="isPlaying"
                      matRipple
                      matTooltip="Pause"
                      class="ui-icon-pause-new"
                      [class.primary]="isCameraView"
                      (click)="pause.emit()"
                ></span>
                <span matRipple
                      matTooltip="Previous alert"
                      matTooltipClass="sidebar-tooltip"
                      class="ui-icon-prev-alert"
                      (click)="prevAlert()"
                ></span>
                <span matRipple
                      matTooltip="Next alert"
                      matTooltipClass="sidebar-tooltip"
                      class="ui-icon-next-alert"
                      (click)="nextAlert()"
                ></span>
                <div *ngIf="isCameraView" [class.disabled]="isCameraView && streamLiveView">
                  <ui-button type="secondary" [disabled]="streamLiveView"
                             (clickAction)="toggleToLiveView.emit()"
                             [class.disabled]="streamLiveView"
                  >Live view
                  </ui-button>
                </div>
              </div>
              <ng-container *ngIf="!isCameraView; else liveViewMode">
                <span class="date">{{ date }}</span>
              </ng-container>
              <ng-template #liveViewMode>
                <div class="p-r">
                  <ui-datetime-range-picker #datePicker
                                            [single]="true"
                                            [inputStyle]="uiInputStyles.noLabel"
                                            [startDate]="datePicker?.maskChanges ? pickerDate : now"
                                            [endDate]="datePicker?.maskChanges ? pickerDate : now"
                                            (pickerOpen)="onPickerOpen()"
                                            (onRangeSelected)="changeDateRange($event)"
                                            [timezone]="timezone"
                                            [showCurrentTime]="streamLiveView"
                                            [icon]="true"
                                            timeFormat="YYYY MMM DD, h:mm:ss A"
                  ></ui-datetime-range-picker>
                </div>
                <div class="playback-actions right camera-view">
                <span
                  *ngIf="isCreateArchivePermission|ngrxPush"
                  class="ui-icon-archive c-p"
                  matTooltip="Create archive"
                  matTooltipClass="sidebar-tooltip"
                  [class.disabled]="streamLiveView"
                  (click)="showCreateArchiveModal.emit()"
                ></span>
                </div>
              </ng-template>

            </div>
            <div class="time-series-content w-100 flex-row"
                 (mousemove)="moveMouseNeedle($event)"
                 (mouseleave)="resetMouseNeedle()"
            >
              <div *ngIf="isCameraView" class="spacer camera-view">&nbsp;</div>
              <timeline-series [start]="start" class="flex"
                               [end]="end"
                               [timezone]="timezone"
                               [percent]="isCameraView ? 0.5 : percent"
                               [zoomEnabled]="zoomEnabled && !(isCameraView && streamLiveView)"
                               (timelineDragEnd)="_timelineDragEnd($event)"
                               (timelineDrag)="_timelineDrag($event)"
                               (timelineDragStart)="timelineDragStart.emit()"
                               (mousedown)="timeClickDown($event)"
                               (mouseup)="timeClickUp($event)"
                               [fixedRange]="fixedRange"
                               [timestamp]="ts"
                               [isCameraView]="isCameraView"
              ></timeline-series>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="w-100">
          <div fxLayout="column"
               fxLayoutAlign="start center"
               class="histogram-names"
               [class.camera-view]="isCameraView"
          >
            <ng-container *ngFor="let cam of videoSection ? [selectedCameras[0]] : selectedCameras; let i = index">
              <div fxLayoutAlign="space-between center" [class.primary]="i === 0"
                   *ngIf="cam && positions[i] < displayCount"
                   fxLayoutGap="12px"
              >
                <!--                <div class="histogram-color"-->
                <!--                     [style.background-color]="ThumbnailHistogramColor[i]"-->
                <!--                ></div>-->
                <div *ngIf="!isCameraView">{{ cam.edgeOnly?.name }}</div>
                <div fxLayout="column" fxLayoutAlign="space-around center" class="h-100">
                  <div class="icon-12 svg-fill-gray" fxLayoutAlign="center center">
                    <mat-icon svgIcon="event"></mat-icon>
                  </div>
                  <div *ngIf="searchEvents && i === 0" class="icon-12 svg-fill-gray" fxLayoutAlign="center center">
                    <mat-icon svgIcon="search"></mat-icon>
                  </div>
                  <div *ngIf="i === 0" class="icon-12 svg-fill-gray" fxLayoutAlign="center center">
                    <mat-icon svgIcon="save"></mat-icon>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="w-100 " fxFlex="">
            <div #timeline
                 style="z-index: 999"
                 class="p-r times-wrap"
                 (mousemove)="moveMouseNeedle($event)"
                 (mouseleave)="resetMouseNeedle()"
            >
              <div #mouseNeedle class="mouse-needle">
                <div class="p-r">
                  <div #thumbPrev class="thumb-preview"
                       [class.fixed-right]=""
                  >
                    <ui-thumbnail-preview class="w-100 h-100"
                                          [data]="previewData"
                                          [ts]="previewTs"
                    ></ui-thumbnail-preview>
                  </div>
                </div>
              </div>
              <div #scroller #scrollerDrag="cdkDrag" cdkDrag [cdkDragBoundary]="timeline" cdkDragLockAxis="x"
                   (cdkDragMoved)="timeDragMove($event)"
                   (cdkDragStarted)="timeDragStart($event)"
                   (cdkDragEnded)="timeDragEnd($event)"
                   class="thumbnail-timeline-scroller multiple c-p"
                   [style.height.px]="(videoSection ? 1 : (camerasCount > displayCount ? displayCount : camerasCount)) * 24 + 24"
                   (mousemove)="$event.stopPropagation()"
                   [cdkDragDisabled]="isCameraView"
              >
                <div cdkDragHandle
                     *ngIf="false"
                     class="thumbnail-timeline-scroller-time"
                     fxLayoutAlign="center center"
                     [style.left]="scroller.offsetLeft < 40 ? - 20 - scroller.offsetLeft + 'px'  :
                (timeline.clientWidth - scroller.offsetLeft < 50 ? - 120 + (timeline.clientWidth - scroller.offsetLeft) + 'px' : '-60px')"
                >
                  <!--              <div>{{ time }}</div>-->
                </div>
                <div *ngIf="!isCameraView" class="thumbnail-timeline-scroller-needle"></div>
              </div>
              <div #histoContainer
                   fxLayout="column"
                   fxLayoutAlign="start center"
                   class="multiple-histogram"
                   [style.height.px]="(videoSection ? 1 : (camerasCount > displayCount ? displayCount : camerasCount)) * 24 + 24"
              >
                <ng-container *ngFor="let cam of videoSection ? [selectedCameras[0]] : selectedCameras; let i = index;">
                  <div fxLayout="column"
                       fxLayoutAlign="start center"
                       class="multiple-histogram-line"
                       [class.primary]="i === 0"
                  >
                    <!--                    <app-events-histogram-->
                    <!--                      class="w-100"-->
                    <!--                      [fxFlex]="!!searchEvents ? ''+ 100 / 3 : '50'"-->
                    <!--                      [rtl]="rtl"-->
                    <!--                      [events]="isNow && events?.length && !dragged ? events : cameraEvents[i]"-->
                    <!--                      [startTs]="start"-->
                    <!--                      [endTs]="end"-->
                    <!--                      *ngIf="cam && positions[i] < displayCount"-->
                    <!--                      [parentWidth]="timelineWrapper.clientWidth"-->
                    <!--                    ></app-events-histogram>-->
                    <ng-container *ngIf="i===0; else multiCam">
                      <app-thumbnail-histogram
                        #thumbnailHistogram
                        [edgeId]="cam?.edgeId"
                        [cameraId]="cam?.edgeOnly?.cameraId ?? cam?.cameraId" [start]="start"
                        class="w-100"
                        [fxFlex]="!!searchEvents && i === 0? ''+100/3 : '50'"
                        [end]="end"
                        *ngIf="cam && positions[i] < displayCount"
                        [parent]="timelineWrapper"
                        [isAlertNow]="isAlert && isNow && !dragged"
                        [isCameraView]="isCameraView"
                      ></app-thumbnail-histogram>
                      <app-events-histogram
                        #eventsHistogram
                        class="w-100"
                        [fxFlex]="!!searchEvents && i === 0 ? ''+ 100 / 3 : '50'"
                        [rtl]="rtl"
                        [searchEvents]="i === 0 ? searchEvents: undefined"
                        [startTs]="start"
                        [endTs]="end"
                        *ngIf="!!searchEvents && cam && i === 0"
                        [parent]="timelineWrapper"
                      ></app-events-histogram>
                      <app-storage-histogram
                        #storageHistogram
                        [edgeId]="cam?.edgeId"
                        [cameraId]="cam?.edgeOnly?.cameraId ?? cam?.cameraId" [start]="start"
                        class="w-100"
                        [fxFlex]="!!searchEvents && i === 0 ? ''+100/3 : '50'"
                        [end]="end"
                        *ngIf="cam && positions[i] < displayCount"
                        [parent]="timelineWrapper"
                        [isCameraView]="isCameraView"
                      ></app-storage-histogram>
                    </ng-container>
                    <ng-template #multiCam>
                      <app-thumbnail-histogram
                        #thumbnailHistogram
                        [edgeId]="cam?.edgeId"
                        [cameraId]="cam?.edgeOnly?.cameraId ?? cam?.cameraId" [start]="start"
                        class="w-100"
                        [fxFlex]="!!searchEvents && i === 0 ? ''+100/2 : '100'"
                        [end]="end"
                        *ngIf="cam && positions[i] < displayCount"
                        [parent]="timelineWrapper"
                        [isAlertNow]="isAlert && isNow && !dragged"
                        [isCameraView]="isCameraView"
                      ></app-thumbnail-histogram>
                    </ng-template>
                  </div>
                  <!--                  <app-storage-histogram fxLayout="row"-->
                  <!--                                         class="w-100"-->
                  <!--                                         [style.height.px]="5"-->
                  <!--                                         [rtl]="rtl"-->
                  <!--                                         [events]="storageStats(cam?.edgeId, cam?.edgeOnly?.cameraId) | async"-->
                  <!--                                         [startTs]="start"-->
                  <!--                                         [endTs]="end"-->
                  <!--                                         *ngIf="cam && positions[i] < displayCount && (storageStats(cam?.edgeId, cam?.edgeOnly?.cameraId) | async)?.length"-->
                  <!--                  ></app-storage-histogram>-->

                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
