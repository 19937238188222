<div #wrapper
     fxLayout="column"
     fxFlex=""
     class="p-wrapper p-r"
     appVisibilityChange
     (visibilityChanged)="checkHiddenDocument($event)"
>
  <ng-container *ngrxLet="isDeveloper$; let isDeveloper">
    <div *ngIf="isDeveloper && liveViewType === LiveViewType.Livekit"
         matRipple
         fxLayout="row"
         fxLayoutAlign="center center"
         class="participants c-p hover"
         matTooltip="Show participants"
         [class.camera-view]="cameraView"
         (click)="showParticipants(isDeveloper); $event.stopPropagation();"
    >
      <span class="ui-icon-eye"></span>
    </div>
    <div *ngIf="!playback && isDeveloper" class="flex-row gap-8 live-view-selection hover">
      <mat-select class="ui-selector"
                  [value]="liveViewType"
                  (valueChange)="liveViewTypeChange($event)"
                  [style.width.px]="150"
      >
        <mat-option [value]="LiveViewType.Webrtc">Webrtc</mat-option>
        <mat-option [value]="LiveViewType.Livekit">LiveKit</mat-option>
        <mat-option [value]="LiveViewType.CloudflareCalls">Cloudflare Calls</mat-option>
      </mat-select>
    </div>
    <div class="flex-row gap-8 ws-test"

         *ngIf="false && isDeveloper"
    >
      <ui-toggle [(ngModel)]="wsPlayback" (ngModelChange)="enableWSPlaybackTest()"></ui-toggle>
      <div>Websocket playback</div>
    </div>
  </ng-container>
  <div fxLayout="row"
       fxLayoutAlign="start center"
       fxLayoutGap="10px"
       class="webrtc-state"
       [class.loading]="loader && !(isTileSelected$ | ngrxPush)"
       [class.camera-view]="!cameraName"
       *ngrxLet="selectHasSubstream$; let hasSubstream"
  >
    <div *ngIf="cameraName"
         [class.small]="isSmallTile"
         class="camera-name flex-row"
    >
      <span class="ui-icon-status-online "></span>
      {{ cameraName }}
    </div>
    <!--    <ui-quality-selector-->
    <!--      *ngIf="!(isLocal && localLiveStreamEnabled) && (isConnected || qualityChange)"-->
    <!--      class="hover"-->
    <!--      [class.disabled]="!isPlaying"-->
    <!--      #qualitySelector-->
    <!--      [scale]="!state.hq" (qualityChanged)="changeQuality($event);"-->
    <!--    ></ui-quality-selector>-->
    <ng-container *ngIf="(!isLiveKit || hasSubstream) && !playback">
      <ui-quality-selector-v2 #resolutionSelector [class.hover]="!resolutionSelector?.selector?.panelOpen"
                              *ngIf="!hideQuality && !(isLiveKit || isLocal && localLiveStreamEnabled) && (isConnected || qualityChange) && !playback"
                              [(ngModel)]="localState.resolution"
                              (click)="$event.stopPropagation()"
                              (qualityChanged)="changeQuality($event)"
      ></ui-quality-selector-v2>
      <ui-quality-selector-v2 #resolutionSelector [class.hover]="!resolutionSelector?.selector?.panelOpen"
                              *ngIf="!hideQuality && isLiveKit || isLocal && localLiveStreamEnabled && !playback"
                              [(ngModel)]="localState.resolution"
                              (click)="$event.stopPropagation()"
                              [enableSQ]="!isLiveKit"
                              (qualityChanged)="changeResolution($event)"
      ></ui-quality-selector-v2>
    </ng-container>
    <div *ngIf="false" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <ui-toggle [(ngModel)]="recover"></ui-toggle>
      <div style="font-weight: 500; color: #fff">Auto recovery</div>
    </div>
  </div>
  <div #placeholder [class.hide]="!isPlaceholder"
       class="placeholder"
  >
    <canvas #placeholderCanvas width="900" height="500"></canvas>
  </div>
  <div fxLayout="row"
       fxLayoutAlign="start center"
       fxLayoutGap="10px"
       [class.small]="isSmallTile"
       [class.hover]="isSmallTile"
  ></div>
  <div #snapshotWrapper
       *ngIf="!(isLocal && localState?.videoStarted) && (!started || !started && isPlaying)"
       @fadeOut
       class="placeholder"
  >
    <img class="h-100 w-100"
         #snapshot
         [lazyLoad]="{edgeId:this.edgeId, cameraId: this.cameraId } | cameraBestImagePipe | async"
         [defaultImage]="{edgeId:this.edgeId, cameraId: this.cameraId } | cameraThumbnailImagePipe"
         errorImage="assets/snapshot-placeholder.jpg"
         (error)="snapshot.alt = 'error'"
    >
  </div>
  <div class="webrtc-state" style="position: absolute; top: 5px; right: 5px; display: none;">
    {{ this.state?.pc?.connectionState }}
  </div>
  <div class="webrtc-local" style="position: absolute; bottom: 5px; right: 5px; display: none;">
    {{ disableInactivity ? 'LOCAL' : 'RELAY' }}
  </div>
  <div *ngIf="!!sessionId && !(isLocal && localLiveStreamEnabled) && isRespectRatio && liveViewType === LiveViewType.Webrtc"
       fxLayout="row"
       fxLayoutAlign="start center"
       fxLayoutGap="10px"
       class="stats-container"
  >
    <div>{{ stats.bitrate | number : '1.0-0' }} Kbit/s</div>
    <div class="stats hover" *ngIf="developer" matTooltip="Show statistics">
      <mat-icon (click)="openStats();$event.stopPropagation()">equalizer</mat-icon>
    </div>
  </div>
  <div *ngIf="loader" fxLayoutAlign="center center" style="position: absolute; z-index: 998" class="w-100 h-100">
    <ui-preloader [color]="preloaderColor.blue" [diameter]="45"></ui-preloader>
  </div>
  <div *ngIf="showRetry"
       fxLayoutAlign="center center"
       style="position: absolute; z-index: 998"
       class="w-100 h-100 retry-button"
  >
    <span class="ui-icon-reset" matTooltip="Click to retry" (click)="retry()"></span>
  </div>
  <div *ngIf="allowZoom && zoomState.scale > 1" fxLayout="column" fxLayoutGap="5px" class="current-zoom-label hover"
       (click)="resetZoom()"
  >
    <div class="reset">Reset</div>
    <div class="zoom">{{ zoomState.scale * 100 | number : '1.0-0' }}%</div>
  </div>
  <div *ngIf="allowZoom && showZoomButtons"
       [class.small]="isSmallTile"
       fxLayout="column"
       fxLayoutGap="5px"
       class="manual-zoom-wrapper c-p hover"
  >
    <div fxLayoutAlign="center center" (click)="zoomIn();$event.stopPropagation()">
      <mat-icon>add</mat-icon>
    </div>
    <div fxLayoutAlign="center center" (click)="resetZoom();$event.stopPropagation()">
      <mat-icon>refresh</mat-icon>
    </div>
    <div fxLayoutAlign="center center" (click)="zoomOut(); $event.stopPropagation()">
      <mat-icon>remove</mat-icon>
    </div>
  </div>
  <div #preview
       class="preview"
       [class.display-none]="!inZoom()"
       *ngIf="zoomPreview"
       cdkDragBoundary=".p-wrapper"
       cdkDrag
       (cdkDragStarted)="preview.classList.add('move')"
       (cdkDragEnded)="preview.classList.remove('move')"
       [ngClass]="{ invisible: zoomState.scale === 1 }"
       (mousedown)="$event.stopPropagation();"
  >
    <canvas #previewCanvas id="c1" width="208" height="117"></canvas>
  </div>

  <div class="video-wrapper" #videoWrapper
       (mousewheel)="zoom($event); $event.stopPropagation()"
       (mousedown)="dragStart($event);$event.stopPropagation()"
       (mouseup)="zoomState.dragging = false"
       (mousemove)="drag($event);$event.stopPropagation()"
       [ngClass]="{ grab: zoomState.dragging }"
  >

    <video [class.isCoverMode]="!isRespectRatio"
           [class.isDefault]="isRespectRatio"
           #player
           controls
           crossorigin
           playsinline
           muted="muted"
           id="player"
           (timeupdate)="onTimeUpdate($event)"
    ></video>
  </div>
</div>
